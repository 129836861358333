import * as React from "react"
import {useState} from "react"
import {
    ActionWrapper,
    IframeWrapper,
    PlayButton,
    StyledContainer,
    TextWrapper,
    VideoContainer,
    VideoOuterWrapper,
    Wrapper
} from "./styles"
import {VideoComponentProps} from "./types"
import YouTube from "react-youtube"
import Modal from "@components/Modal"

const VideoComponent: React.FC<VideoComponentProps> = ({content, video}) => {
    const [show, setShow] = useState(false)

    return <VideoOuterWrapper>
        <StyledContainer>
            <TextWrapper>
                <div dangerouslySetInnerHTML={{__html: content}}/>
            </TextWrapper>
        </StyledContainer>
        <VideoContainer>
            <Wrapper onClick={() => setShow(true)}>
                {video.posterImage}
                <ActionWrapper>
                    <PlayButton ria-label="Play">
                        {video.buttonLabel}<img src={"/images/play-button.svg"}/>
                    </PlayButton>
                </ActionWrapper>
            </Wrapper>
            {show && <Modal open={show} onClose={() => setShow(false)} /*mobileFullScreen={false}*/>
                <IframeWrapper>
                    <iframe width="400" height="500" frameBorder="0"
                            src="https://www.bbc.co.uk/news/av-embeds/42747892/vpid/p05vpt9v"/>
                    {/*<YouTube*/}
                    {/*    videoId={video.youtubeId}*/}
                    {/*    opts={{*/}
                    {/*        height: "390",*/}
                    {/*        width: "640",*/}
                    {/*        playerVars: {*/}
                    {/*            autoplay: 1*/}
                    {/*        }*/}
                    {/*    }}*/}
                    {/*/>*/}
                </IframeWrapper>
            </Modal>}
        </VideoContainer>
    </VideoOuterWrapper>
}

export default VideoComponent
