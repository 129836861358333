import * as React from "react"

const KruisjeVinkjeIcon = () => <svg width="40" height="31" viewBox="0 0 40 31" xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
        <polygon id="kruisje_vinkje_svg__a" points="0 0 40 0 40 30.4334958 0 30.4334958"/>
    </defs>
    <g fill="none" fillRule="evenodd">
        <mask id="kruisje_vinkje_svg__b" fill="#fff">
            <use xlinkHref="#kruisje_vinkje_svg__a"/>
        </mask>
        <path d="M0 6.62256267C1.35097493 6.39449861 1.77924791 5.61107242 3.74477716 5.55188022 3.83530641 7.03342618 4.39763231 8.62987465 4.72144847 10.0696379 5.1810585 12.1222145 5.80779944 13.5445682 6.0393454 14.3192897 8.47667131 11.4049443 11.0027855 9.69011142 13.8022284 7.02472145 15.920961 5.00696379 17.7176184 2.62534819 20.2820334 0 22.9160864 1.4014624 20.3568942 4.30362117 18.8579387 6.09505571 16.4536908 8.97284123 15.6859331 10.1149025 13.0222841 12.6218663 7.05779944 18.2381616 2.7994429 23.3582869.945334262 15.3046657.311629526 12.5522284.311629526 9.45334262 0 6.62256267M26.1803621 13.6089833C26.8906685 15.7590529 28.3774373 19.2670613 29.6744429 21.0793872 27.8238162 22.8830084 26.8192897 24.7005571 24.6761838 26.1281337 25.5692897 27.5017409 26.0898329 28.6055014 27.2893454 30.3708217 28.5410864 28.2660167 29.8798747 26.7078691 31.4850279 24.6413649 32.235376 26.5894847 34.112117 28.1145543 35.3255571 29.7632312 35.9488162 28.2712396 37.4912953 27.235376 38.6490251 26.1629526 36.9045961 24.8119777 35.4213092 22.8725627 34.0337744 21.4206128 36.3736072 19.0842618 37.5017409 17.3659471 40 14.5473538 39.3366992 13.5550139 38.814415 12.7924791 37.9143454 12.0856546 36.2082173 14.9129526 34.4220056 15.9018106 32.1761838 18.2486072 31.3683844 16.0654596 29.7179666 14.3314763 28.9014624 12.1535515L26.1803621 13.6089833M17.2214485 17.4408078C14.8920613 21.678273 13.354805 26.4327994 10.8025766 30.3394847 14.0407382 30.4021588 14.9808496 31.3300836 16.3057103 28.5550139 17.6671309 25.7050836 18.9745822 22.7994429 20.3655989 19.9321031 24.2688022 11.8854457 25.5292479 9.09993036 30.0713788 1.64345404 28.8649025 1.07416435 25.9766713 1.38405292 25.3046657 1.20473538 25.3046657 1.20473538 21.1002786 10.3882312 17.2214485 17.4408078"
              fill="#658AB9" mask="url(#kruisje_vinkje_svg__b)"/>
    </g>
</svg>

export default KruisjeVinkjeIcon
