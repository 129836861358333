import * as React from "react"
import {
    Arrow,
    Link,
    Next,
    Prev,
    ProductOverviewWrapper,
    StyledContainer,
    StyledSwiper,
    TextWrapper,
} from "./styles"
import {ProductOverviewProps} from "./types"
import {Navigation, Pagination} from "swiper"
import ArrowLeftIcon from "@icons/ArrowLeft"
import ArrowRightIcon from "@icons/ArrowRight"
import useSwiperRef from "@hooks/useSwiperRef"
import "swiper/css/bundle"
import {SwiperSlide} from "swiper/react"
import useMediaQuery from "@hooks/useMediaQuery"

const ProductOverview: React.FC<ProductOverviewProps> = ({content, products}) => {
    const [nextEl, nextElRef] = useSwiperRef()
    const [prevEl, prevElRef] = useSwiperRef()

    const isMobile = useMediaQuery("(max-width: 768px)")

    return <ProductOverviewWrapper>
        <StyledContainer>
            <TextWrapper>
                <div dangerouslySetInnerHTML={{__html: content}}/>
            </TextWrapper>
            <StyledSwiper
                modules={[Pagination, Navigation]}
                navigation={{
                    nextEl: nextEl,
                    prevEl: prevEl,
                }}
                watchSlidesProgress
                pagination={{clickable: true}}
                spaceBetween={20}
                breakpoints={{
                    0: {
                        slidesPerView: 1,
                    },
                    400: {
                        slidesPerView: 1.2,
                    },
                    768: {
                        slidesPerView: 2,
                    },
                    1024: {
                        slidesPerView: 3,
                    },
                    1440: {
                        slidesPerView: 4,
                    }
                }}
            >
                {products && products.map((product, index) => (
                    <SwiperSlide key={index}>
                        <Link href={product.link} target="_blank" rel="noreferrer noopener">
                            <span>{product.overline}</span>
                            <h3>{product.title}</h3>
                            {product.image}
                            <Arrow/>
                        </Link>
                    </SwiperSlide>
                ))}
                {isMobile &&
                <>
                    <Prev ref={prevElRef}>
                        <ArrowLeftIcon/>
                    </Prev>
                    <Next ref={nextElRef}>
                        <ArrowRightIcon/>
                    </Next>
                </>}
            </StyledSwiper>
        </StyledContainer>
    </ProductOverviewWrapper>
}

export default ProductOverview
