import * as React from "react"
import {BackgroundWrapper, ComingSoonContainer, ComingSoonWrapper, ImageWrapper, Text, TextWrapper} from "./styles"
import {ComingSoonProps} from "./types"
import {Row, StyledText, UspItem, UspTextWrapper} from "@components/TextWithList/styles";

const ComingSoon: React.FC<ComingSoonProps> = ({backgroundImage, image, list, content}) => {
    return <ComingSoonWrapper>
        <ComingSoonContainer>
            <TextWrapper>
                <Text dangerouslySetInnerHTML={{__html: content}}/>
                <Row>
                    {list && list.map((usp, index) => (
                        <UspItem key={index}>
                            <UspTextWrapper>
                                <span>{usp.titleLeft}</span>
                                <div dangerouslySetInnerHTML={{__html: String(usp.titleRight)}}/>
                            </UspTextWrapper>
                            <StyledText>{usp.text}</StyledText>
                        </UspItem>
                    ))}
                </Row>
            </TextWrapper>
        </ComingSoonContainer>
        {image && <ImageWrapper>
            {image}
        </ImageWrapper>}
        {backgroundImage && <BackgroundWrapper>
            {backgroundImage}
        </BackgroundWrapper>}
        {!image && <div style={{ height: 40 }} />}
    </ComingSoonWrapper>
}

export default ComingSoon
