import styled from "@emotion/styled"
import Container from "@components/Container";

export const FooterContainer = styled(Container)(({theme}) => ({
    display: "flex",
    color: "#fff",
    paddingLeft: 20,
    alignItems: "center",
    paddingRight: 20,
    fontFamily: theme.fonts.filson,
    fontSize: theme.fonts.pxToRem(14),
    lineHeight: "19px",
    "& > span": {
        marginBottom: 10,
        fontFamily: theme.fonts.filson,
        fontWeight: theme.fontWeights.bold,
        fontSize: theme.fonts.pxToRem(12),
        lineHeight: "14px",
        textTransform: "uppercase",
        color: "#F7BCB3"
    },
    [theme.breakpoint.down("lg")]: {
        flexDirection: "column"
    }
}))
export const FooterWrapper = styled("section")(({theme}) => ({
    width: "100%",
    marginTop: -1,
    maxWidth: 1730,
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: theme.colors.primary,
}))


export const BottomLinksContainer = styled(FooterContainer)(({theme}) => ({
    paddingTop: "20px !important",
    paddingBottom: "40px !important",
    "& a": {
        opacity: .6,
        width: "fit-content",
        "&:first-child": {
            marginRight: 30
        }
    },
    [theme.breakpoint.down("md")]: {
        flexWrap: "wrap",
        fontFamily: theme.fonts.filson,
        fontSize: theme.fonts.pxToRem(12),
        "& a": {
            width: "100%",
            "&:not(:last-of-type)": {
                marginBottom: 10
            },
            "&:last-of-type": {
                marginBottom: 20
            }
        }
    },
    [theme.breakpoint.up("xs")]: {
        flexDirection: "row",
    }
}))

export const Line = styled("div")(({theme}) => ({
    width: "100%",
    height: 1,
    backgroundColor: "rgb(116, 149, 186, .2)"
}))

export const LogoWrapper = styled("div")(({theme}) => ({
    marginRight: 100,
    [theme.breakpoint.down("lg")]: {
        marginRight: 0,
        marginBottom: 40
    },
    [theme.breakpoint.down("md")]: {
        width: 180,
        "& img": {
            width: "100%"
        }
    }
}))

export const QuickLinkColumn = styled("div")(({theme}) => ({
    display: "flex",
    flexDirection: "column",
    "& a": {
        fontWeight: theme.fontWeights.thin,
        marginBottom: 10,
        "&:last-child": {
            marginBottom: 0
        }
    },
    "&:first-child": {
        marginRight: 40,
        [theme.breakpoint.down("md")]: {
            marginRight: 0,
            marginBottom: 10
        }
    }
}))

export const QuickLinksWrapper = styled("div")(({theme}) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    [theme.breakpoint.down("md")]: {
        marginBottom: 20
    },
    "& span": {
        display: "block",
    }
}))

export const QuickLinkContainer = styled("div")(({theme}) => ({
    display: "flex",
    width: "100%",
    flex: "0 0 100%",
    [theme.breakpoint.down("md")]: {
        flexDirection: "column"
    }
}))

export const InnerFooterWrapper = styled("div")(({theme}) => ({
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoint.down("md")]: {
        flexDirection: "column"
    }
}))

export const ContactInfoWrapper = styled("div")(({theme}) => ({
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    width: "50%",
    fontWeight: theme.fontWeights.thin,
    [theme.breakpoint.down("md")]: {
        width: "100%"
    },
    "& p": {
        margin: 0,
        padding: 0,
        fontFamily: theme.fonts.filson,
        fontSize: theme.fonts.pxToRem(14),
        height: 30,
        [theme.breakpoint.down("md")]: {
            height: "auto",
        }
    },
    "& div > p": {
        lineHeight: "19px",
        marginBottom: 10,
        "&:last-child": {
            marginBottom: 0
        }
    }
}))

export const FooterLangueSelectorWrapper = styled("div")(({theme}) => ({
    marginLeft: "auto",
}))

export const InnerWrapper = styled("div")(({theme}) => ({
    display: "flex",
    alignItems: "center",
    marginTop: -10,
    "& > span": {
        marginRight: 10,
        fontFamily: theme.fonts.filson,
        fontWeight: theme.fontWeights.bold,
        fontSize: theme.fonts.pxToRem(12),
        lineHeight: "14px",
        textTransform: "uppercase",
        color: "#F7BCB3"
    },
    "& > *": {
        marginTop: 10,
    },
    flexWrap: "wrap"
}))
