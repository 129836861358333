import * as React from "react"
import {StyledContainer} from "./styles"
import {ContainerProps} from "./types"
import {HTMLAttributes} from "react"

const Container: React.FC<ContainerProps & HTMLAttributes<HTMLDivElement>> = ({children, maxWidth, noPadding, ...props}) => {
    return <StyledContainer maxWidth={maxWidth} noPadding={noPadding} {...props}>{children}</StyledContainer>
}

export default Container
