import * as React from "react"
import {useEffect, useRef, useState} from "react"
import {ModalProps} from "./types"
import {Blur, CloseButton, Container, ModalWrapper, Wrapper} from "./styles"
import CrossIcon from "@icons/CrossIcon"
import ReactDOM from "react-dom"
import {handleEscKeyPress} from "@utils/handleEscKeyPress"

const Modal: React.FC<ModalProps> = ({
    open,
    onClose,
    children,
    small,
    mobileFullScreen = true,
}) => {
    const [modalRoot, setModalRoot] = useState<HTMLDivElement | null>(null)
    const wrapperRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (open && wrapperRef?.current && !wrapperRef.current.contains(event.target as HTMLElement)) {
                onClose && onClose()
            }
        }

        document.addEventListener("keydown", (e) => handleEscKeyPress(e, onClose))
        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            document.removeEventListener("keydown", (e) => handleEscKeyPress(e, onClose))
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [wrapperRef])

    useEffect(() => {
        setModalRoot(document.querySelector<HTMLDivElement>("#modal-root"))
    }, [])

    if (!modalRoot) {
        return <></>
    }

    return ReactDOM.createPortal(
        <>
            <Container open={open}>
                <Wrapper mobileFullScreen={mobileFullScreen}>
                    <ModalWrapper small={!!small} ref={wrapperRef}>
                        {onClose &&
                        <CloseButton onClick={() => onClose && onClose()} type={"button"}><CrossIcon/></CloseButton>}
                        {children}
                    </ModalWrapper>
                </Wrapper>
            </Container>
            <Blur open={open} onClick={() => onClose && onClose()}/>
        </>,
        modalRoot,
    )
}

export default Modal
