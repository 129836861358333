import styled from "@emotion/styled"
import Container from "@components/Container";

export const ComingSoonWrapper = styled.div({
    backgroundColor: "#9DC5BC",
    overflow: "hidden",
})

export const ComingSoonContainer = styled(Container)(({theme}) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    overflow: "hidden",
    padding: "0px !important"
}))

export const TextWrapper = styled("div")(({theme}) => ({
    zIndex: 1,
    paddingLeft: 40,
    paddingRight: 40,
    "@media (max-width: 768px)": {
        paddingLeft: 30,
        paddingRight: 30,
    },
    "& h2": {
        marginBottom: 30,
        color: theme.colors.primary,
        [theme.breakpoint.down("md")]: {
            marginBottom: 20,
        }
    }
}))

export const ImageWrapper = styled("div")(({theme}) => ({
    position: "relative",
    alignSelf: "left",
    marginLeft: "auto",
    marginRight: "auto",
    // marginTop: 70,
    zIndex: 1,
    width: "70%",
    [theme.breakpoint.down("lg")]: {
        width: "80%",
    },
    [theme.breakpoint.down("md")]: {
        // marginTop: 40,
        width: "100%",
    },
    "& img": {
        display: "block",
        width: "100%",
        height: "auto",
        [theme.breakpoint.down("lg")]: {
            // width: "calc(100% - 80px)",
            marginLeft: "auto",
            marginRight: "auto",
        }
    }
}))

export const BackgroundWrapper = styled("div")(({theme}) => ({
    position: "relative",
    height: 0,
    width: "100%",
    pointerEvents: "none",
    userSelect: "none",
    "& img": {
        position: "absolute",
        left: 0,
        bottom: 0,
        display: "block",
        width: "100%",
        height: "auto",
        // [theme.breakpoint.up("xl")]: {
        //     top: -290,
        //     // bottom: "auto",
        //     transform: "translateY(0)",
        // },
        // [theme.breakpoint.up("xxl")]: {
        //     top: -330,
        // },
        // [theme.breakpoint.up("xxxl")]: {
        //     top: -375,
        // },
        [theme.breakpoint.down("md")]: {
            width: "150vw",
            top: "auto",
            bottom: -60
        },
        [theme.breakpoint.down("xl")]: {
            bottom: -100
        },
        [theme.breakpoint.up("xl")]: {
            bottom: -250
        }
    }
}))

export const Text = styled("div")(({theme}) => ({
    color: theme.colors.secondary,
    maxWidth: 750,
    marginBottom: 40,
    fontSize: theme.fonts.pxToRem(18),
    lineHeight: "30px",
    fontFamily: theme.fonts.filson,
    [theme.breakpoint.down("md")]: {
        fontSize: theme.fonts.pxToRem(16),
    }
}))