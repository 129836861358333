import * as React from "react"

const KruisjeIcon = () => <svg width="26" height="32" viewBox="0 0 26 32" xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
        <polygon id="kruisje_svg__a" points="0 0 26 0 26 31.0267643 0 31.0267643"/>
    </defs>
    <g fill="none" fillRule="evenodd">
        <mask id="kruisje_svg__b" fill="#fff">
            <use xlinkHref="#kruisje_svg__a"/>
        </mask>
        <path
            d="M2.49781659,3.48302578 C3.6551627,6.99535146 6.08522327,12.7271447 8.20397239,15.6900972 C5.17875757,18.6383998 3.38963234,22.718411 -0.10987463,25.0495844 C1.34596422,27.2928581 1.98506832,28.2542612 3.94816171,31.1384702 C6.16396676,27.7616566 8.53909001,24.8847725 11.1632624,21.5079589 C12.3883646,24.6924919 15.455698,27.1848148 17.4389351,29.8785744 C18.4571066,27.4411889 20.9787294,25.7472884 22.8649106,23.9966192 C20.0154951,21.7881392 17.5945908,18.6200873 15.3275109,16.2486266 C19.151148,12.4286519 21.9181575,8.62698972 26,4.02141147 C24.9159036,2.39892943 24.0607128,1.15368362 22.5920552,0 C19.3525849,4.44442879 15.8475842,7.78278631 12.1777715,11.6192421 C10.8574447,8.05014791 8.4841527,4.33821665 6.94041414,1.10607128 L2.49781659,3.48302578"
            fill="#B4769C" mask="url(#kruisje_svg__b)"/>
    </g>
</svg>

export default KruisjeIcon
