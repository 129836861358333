import * as React from "react"
import {IconButton, ImageContainer, PlainButton} from "./styles"
import {ButtonProps} from "./types"
import {Link} from "gatsby"
import LoadingIcon from "@icons/LoadingIcon"
import HubspotButton from "@components/HubspotButton";

const Button: React.FC<ButtonProps> = ({
                                           children,
                                           type = "button",
                                           icon,
                                           variant = "primary",
                                           size = "default",
                                           url,
                                           isInternal,
                                           iconRight,
                                           iconLeft,
                                           isLoading,
                                           download,
                                           portalId,
                                           ctaId,
                                           ...props
                                       }) => {

    const noIcon = (!iconRight && !iconLeft) ? "noIcon" : ""
    const buttonProps = {
        type,
        ...props,
        size: size,
        className: `${variant} ${noIcon}`,
        disabled: isLoading || props.disabled,
    }

    const buttonContent = <>
        {iconLeft && <ImageContainer className="icon-left">{iconLeft}</ImageContainer>}
        <span>{children}</span>
        {iconRight && <ImageContainer className="icon-right">{iconRight}</ImageContainer>}
        {isLoading && <ImageContainer className="icon-loading"><LoadingIcon/></ImageContainer>}
    </>
    if (portalId && ctaId) {
        return <HubspotButton variant={variant} children={children} portalId={portalId} ctaId={ctaId}/>
    }
    if (url) {
        const {type, ...linkButtonProps} = buttonProps
        const isExternal = url.startsWith("http") || url.startsWith("//") || isInternal === false

        if (!isExternal) {
            const InternalButton = PlainButton.withComponent(Link)
            return <InternalButton to={url} {...linkButtonProps}>{buttonContent}</InternalButton>
        } else {
            const LinkButton = PlainButton.withComponent("a")
            if (download) {
                return <LinkButton
                    download
                    target="_blank"
                    rel="noreferrer"
                    href={url} {...linkButtonProps}>{buttonContent}</LinkButton>
            } else {
                return <LinkButton href={url} {...linkButtonProps}>{buttonContent}</LinkButton>
            }
        }
    }

    if (icon) {
        return <IconButton {...buttonProps}>{icon}</IconButton>
    }

    return <PlainButton {...buttonProps}>{buttonContent}</PlainButton>
}

export default Button
