import * as React from "react"
import {GatsbyImage} from "gatsby-plugin-image"
import Form from "@components/Form";

const StrapiForm: React.FC = (props: any) => {
    const processedImage = props?.contentImages ? props.contentImages.map((image: any) => image?.formCtaImage?.childImageSharp?.gatsbyImageData).find((entry: any) => entry !== null) : null
    const processedBackgroundImage = props?.contentImages ? props.contentImages.map((image: any) => image?.formBackgroundImage?.childImageSharp?.gatsbyImageData).find((entry: any) => entry !== null) : null
    const processedSvg = props?.contentImages ? props.contentImages.map((image: any) => image?.formCtaImage?.publicURL).find((entry: any) => entry !== undefined) : null
    const processedBackgroundSvg = props?.contentImages ? props.contentImages.map((image: any) => image?.formBackgroundImage?.publicURL).find((entry: any) => entry !== undefined) : null

    return <Form
        content={props.content}
        backgroundImage={processedBackgroundImage
            ? <GatsbyImage image={processedBackgroundImage} alt={props.backgroundImage.alternativeText || ""}/>
            : processedBackgroundSvg
                ? <img src={processedBackgroundSvg} alt={props.backgroundImage.alternativeText || ""}/>
                : undefined}
        ctaImage={processedImage
            ? <GatsbyImage image={processedImage} alt={props.ctaImage.alternativeText || ""}/>
            : processedSvg
                ? <img src={processedSvg} alt={props.ctaImage.alternativeText || ""}/>
                : undefined}
        formTitle={props.formTitle}
        formId={props.formId}
        portalId={props.portalId}
        textBlocks={props.textBlocks}
    />
}

export default StrapiForm
