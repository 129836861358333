import styled from "@emotion/styled"
import {ContainerProps} from "./types"

const breakpointPadding = (breakpoint: string, noPadding?: boolean) => {
    if (noPadding) {
        return {}
    } else if (breakpoint === "md") {
        return {
            paddingTop: 60,
            paddingBottom: 60,
        }
    } else if (breakpoint === "lg") {
        return {
            paddingTop: 90,
            paddingBottom: 90,
        }
    }
}

export const StyledContainer = styled.div<ContainerProps>(
    ({theme, maxWidth, noPadding}) => {
        const maxWidths: { [key: string]: object } = {}
        const indexKey = maxWidth ? theme.breakpointSizes.indexOf(maxWidth) : -1

        for (let i = 0; i < theme.breakpointSizes.length; i++) {
            const breakpointKey = theme.breakpointSizes[i]
            if (theme.maxWidths[i]) {
                maxWidths[theme.breakpoint.up(breakpointKey)] = {
                    maxWidth: `${theme.maxWidths[i]}px`,
                    ...breakpointPadding(breakpointKey, noPadding),
                }
            }
            if (indexKey > -1 && i === indexKey) {
                break
            }
        }

        const defaultPadding = noPadding ? {} : {
            paddingTop: 40,
            paddingBottom: 40,
        }

        return {
            ...defaultPadding,
            ...maxWidths,
            boxSizing: "border-box",
            paddingLeft: 40,
            paddingRight: 40,
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: -1,
            "@media (max-width: 768px)": {
                paddingLeft: 30,
                paddingRight: 30,
            },
        }
    },
)
