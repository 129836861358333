import styled from "@emotion/styled"
import Container from "@components/Container";
import "swiper/css/bundle"
import {Swiper} from "swiper/react"

export const StyledContainer = styled(Container)(({theme}) => ({
    position: "relative",
    paddingTop: "245px !important",
    [theme.breakpoint.down("lg")]: {
        paddingTop: "150px !important",
    },
    [theme.breakpoint.down("md")]: {
        paddingTop: "100px !important",
    }
}))

export const StyledSwiper = styled(Swiper)(({theme}) => ({
    height: "100%",
    width: "80%",
    maxWidth: 1000,
    overflow: "visible",
    display: "none",
    "&.swiper-container": {
        maxWidth: "80%",
    },
    "& .swiper-wrapper": {
        alignItems: "center",
        webkitTransformStyle: "preserve-3d"
    },
    "& .swiper-slide": {
        opacity: 0,
        webkitTransform: "translateZ(0)",
        webkitBackfaceVisibility: "hidden",
        transition: "all .5s",
        // transform: "translate3d(0, 0, 0)",
        "&.swiper-slide-active": {
            opacity: 1
        },
        "& > img, > .gatsby-image-wrapper": {
            width: "80%",
            marginLeft: "auto",
            marginRight: "auto",
            height: "100%",
            objectFit: "contain",
            display: "block",
            background: "transparent",
            position: "relative",
            zIndex: 5
        },
    },
    "& .swiper-pagination-bullet": {
        width: 12,
        height: 12,
        background: "#fff",
        opacity: 1,
        marginLeft: "0 !important",
        marginRight: "12px !Important",
        "&:last-child": {
            marginRight: "0 !important"
        }
    },
    "& .swiper-pagination-bullet-active": {
        background: "#50BA8E"
    },
    [theme.breakpoint.up("md")]: {
        display: "block",
    }
}))

export const StyledMobileSwiper = styled(StyledSwiper)(({theme}) => ({
    width: "100%",
    display: "block",
    "& .swiper-slide": {
        "& > img, > .gatsby-image-wrapper": {
            [theme.breakpoint.down("md")]: {
                width: "100%",
                maxWidth: "100%",
            }
        },
    },
    [theme.breakpoint.up("md")]: {
        display: "none",
    }
}))

export const Next = styled("div")(({theme}) => ({
    position: "absolute",
    bottom: 200,
    right: 40,
    cursor: "pointer",
    width: 46,
    height: 46,
    [theme.breakpoint.down("lg")]: {
        bottom: 170,
    },
    [theme.breakpoint.down("md")]: {
        bottom: 150,
        right: 15
    },
    [theme.breakpoint.up("xxl")]: {
        right: 160,
    },
    [theme.breakpoint.up("xxxl")]: {
        right: 240,
    },
    zIndex: 2,
    "&.swiper-button-disabled": {
        display: "none"
    }
}))

export const Prev = styled(Next)(({theme}) => ({
    left: 40,
    right: 0,
    [theme.breakpoint.down("md")]: {
        left: 15
    },
    [theme.breakpoint.up("xxl")]: {
        left: 160,
    },
    [theme.breakpoint.up("xxxl")]: {
        left: 240,
    }
}))

export const ProductHighlightsWrapper = styled("div")(({theme}) => ({
    width: "100%",
    backgroundColor: theme.colors.tertiary,
    position: "relative",
    overflow: "hidden",
    marginBottom: -1,
}))

export const TextWrapper = styled("div")(({theme}) => ({
    maxWidth: 750,
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
    marginBottom: 40,
    "& h2": {
        marginBottom: 20
    },
    "& p": {
        fontFamily: theme.fonts.filson,
        fontSize: theme.fonts.pxToRem(18),
        lineHeight: "30px",
        color: theme.colors.secondary
    },
    [theme.breakpoint.down("md")]: {
        marginBottom: 60,
        "& p": {
            fontSize: theme.fonts.pxToRem(18),
            lineHeight: "30px"
        },
        textAlign: "left"
    },
    [theme.breakpoint.down("sm")]: {
        "& p": {
            fontSize: theme.fonts.pxToRem(16),
            lineHeight: "30px"
        }
    }
}))

export const HighlightRow = styled("div")(({theme}) => ({
    display: "flex",
    position: "relative",
    zIndex: 10,
    top: -50,
    alignItems: "center",
    justifyContent: "center",
    transform: "translate3d(0, 0, 0)",
}))

export const Highlight = styled("div")(({theme}) => ({
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#fff",
    height: 177,
    width: 427,
    maxWidth: "100%",
    padding: 40,
    boxSizing: "border-box",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #E6E6E6",
    boxShadow: "0 10px 20px 0 rgba(0,0,0,0.08)",
    [theme.breakpoint.down("lg")]: {
        boxShadow: "0 10px 5px 0 rgba(0,0,0,0.08)",
        maxWidth: "calc(100% - 30px)",
    },
    borderRadius: 10,
    "&:first-child": {
        marginRight: 20,
        [theme.breakpoint.down("md")]: {
            marginRight: 0
        },
    },
    "& h3": {
        fontFamily: theme.fonts.filson,
        fontWeight: theme.fontWeights.bold,
        fontSize: theme.fonts.pxToRem(22),
        lineHeight: "28px",
        [theme.breakpoint.down("lg")]: {
            fontSize: theme.fonts.pxToRem(20),
            lineHeight: "26px"
        },
        [theme.breakpoint.down("md")]: {
            fontSize: theme.fonts.pxToRem(18),
            lineHeight: "23px"
        }
    },
    "& p": {
        fontFamily: theme.fonts.filson,
        fontSize: theme.fonts.pxToRem(16),
        lineHeight: "26px",
        color: theme.colors.secondary,
        [theme.breakpoint.down("lg")]: {
            fontSize: theme.fonts.pxToRem(14),
            lineHeight: "24px"
        },
        [theme.breakpoint.down("md")]: {
            fontSize: theme.fonts.pxToRem(14),
            lineHeight: "22px"
        }
    }
}))
