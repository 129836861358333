import React, {FC, SVGAttributes} from "react"

const CrossIcon: FC<SVGAttributes<SVGElement>> = ({...props}) => <svg
    viewBox="0 0 20 20"
    fill="currentColor" {...props}>
    <path
        d="M14.7,5.2c0.4,0.4,0.4,0.9,0.1,1.3l-0.1,0.1l-3.3,3.3l3.3,3.3c0.4,0.4,0.4,1,0,1.4c-0.4,0.4-0.9,0.4-1.3,0.1l-0.1-0.1L10,11.3l-3.3,3.3c-0.4,0.4-1,0.4-1.4,0c-0.4-0.4-0.4-0.9-0.1-1.3l0.1-0.1l3.3-3.3L5.3,6.6c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,0.9-0.4,1.3-0.1l0.1,0.1L10,8.4l3.3-3.3C13.7,4.8,14.3,4.8,14.7,5.2z"
    />
</svg>


export default CrossIcon
