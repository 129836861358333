import StrapiComingSoon from "@components/Strapi/StrapiComingSoon"
import StrapiForm from "@components/Strapi/StrapiForm"
import StrapiTextWithList from "@components/Strapi/StrapiTextWithList"
import StrapiUspIconBlock from "@components/Strapi/StrapiUspIconBlock"
import StrapiTextWithImage from "@components/Strapi/StrapiTextWithImage"
import StrapiVideoComponent from "@components/Strapi/StrapiVideoComponent"
import StrapiProductOverview from "@components/Strapi/StrapiProductOverview"
import StrapiComparison from "@components/Strapi/StrapiComparison"
import StrapiProductHighlights from "@components/Strapi/StrapiProductHighlights"
import StrapiQuote from "@components/Strapi/StrapiQuote"
import StrapiHero from "@components/Strapi/StrapiHero"
import StrapiUspBlock from "@components/Strapi/StrapiUspBlock"
import StrapiContent from "@components/Strapi/StrapiContent"
import StrapiAnchor from "@components/Strapi/StrapiAnchor.stories";

const components: any = {
    "sections.hero": StrapiHero,
    "sections.content": StrapiContent,
    "sections.usp-block": StrapiUspBlock,
    "sections.quote": StrapiQuote,
    "sections.product-highlights": StrapiProductHighlights,
    "sections.comparison": StrapiComparison,
    "sections.product-overview": StrapiProductOverview,
    "sections.video-component": StrapiVideoComponent,
    "sections.text-with-image": StrapiTextWithImage,
    "sections.usp-icon-block": StrapiUspIconBlock,
    "sections.text-with-list": StrapiTextWithList,
    "sections.coming-soon": StrapiComingSoon,
    "sections.form": StrapiForm,
    "components.anchor": StrapiAnchor,
}

export default components