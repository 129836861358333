import * as React from "react"
import {Row, StyledContainer, StyledText, TextWithListWrapper, TextWrapper, UspItem, UspTextWrapper} from "./styles"
import {TextWithListProps} from "./types"

const TextWithList: React.FC<TextWithListProps> = ({content, list}) => {
    return <TextWithListWrapper>
        <StyledContainer>
            <TextWrapper>
                <div dangerouslySetInnerHTML={{__html: content}}/>
            </TextWrapper>
            <Row>
                {list && list.map((usp, index) => (
                    <UspItem key={index}>
                        <UspTextWrapper>
                            <span>{usp.titleLeft}</span>
                            <span dangerouslySetInnerHTML={{__html: usp.titleRight}}/>
                        </UspTextWrapper>
                        <StyledText>{usp.text}</StyledText>
                    </UspItem>
                ))}
            </Row>
        </StyledContainer>
    </TextWithListWrapper>
}

export default TextWithList
