import styled from "@emotion/styled"
import Container from "@components/Container";
import "swiper/css/bundle"
import {Swiper} from "swiper/react"

export const StyledContainer = styled(Container)(({theme}) => ({
    position: "relative",
    paddingBottom: "0px !important"
}))

export const Arrow = styled("div")(({theme}) => ({
    width: 40,
    height: 40,
    borderRadius: "50%",
    background: "#FCCDC5",
    transition: "background 0.2s ease",
    display: "flex",
    alignItems: "center",
    marginLeft: "auto",
    justifyContent: "center",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundImage: `url("data:image/svg+xml,%3Csvg width='21' height='22' viewBox='0 0 21 22' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath d='M7.78872566,0.778310074 C7.35887687,1.20248246 7.35427524,1.8948034 7.77844762,2.32465219 C7.78185082,2.32810093 7.78527691,2.33152702 7.78872566,2.33493022 L12.1784307,6.66666667 L12.1784307,6.66666667 L7.78872566,10.9984031 C7.35887687,11.4225755 7.35427524,12.1148964 7.77844762,12.5447452 C7.78185082,12.548194 7.78527691,12.5516201 7.78872566,12.5550233 C8.22601254,12.9865355 8.92889009,12.9865355 9.36617697,12.5550233 L15.3333333,6.66666667 L15.3333333,6.66666667 L9.36617697,0.778310074 C8.92889009,0.346797822 8.22601254,0.346797822 7.78872566,0.778310074 Z' id='arrow-tilted_svg__a'/%3E%3C/defs%3E%3Cg transform='rotate(-45 13.995 5.045)' fill='none' fill-rule='evenodd'%3E%3Cline x1='12.5' y1='6.5' x2='.5' y2='6.5' stroke='%23000' stroke-width='2.2' stroke-linecap='round'/%3E%3Cuse fill='%23000' xlink:href='%23arrow-tilted_svg__a'/%3E%3C/g%3E%3C/svg%3E")`,
}))

export const Link = styled.a(({theme}) => ({
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
    boxSizing: "border-box",
    flexDirection: "column",
    "& > img, > .gatsby-image-wrapper": {
        maxWidth: "100%",
        marginTop: 30,
        marginBottom: 20
    },
    [`&:hover ${Arrow}`]: {
        background: theme.colors.primary,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundImage: `url("data:image/svg+xml,%3Csvg width='21' height='22' viewBox='0 0 21 22' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath d='M7.78872566,0.778310074 C7.35887687,1.20248246 7.35427524,1.8948034 7.77844762,2.32465219 C7.78185082,2.32810093 7.78527691,2.33152702 7.78872566,2.33493022 L12.1784307,6.66666667 L12.1784307,6.66666667 L7.78872566,10.9984031 C7.35887687,11.4225755 7.35427524,12.1148964 7.77844762,12.5447452 C7.78185082,12.548194 7.78527691,12.5516201 7.78872566,12.5550233 C8.22601254,12.9865355 8.92889009,12.9865355 9.36617697,12.5550233 L15.3333333,6.66666667 L15.3333333,6.66666667 L9.36617697,0.778310074 C8.92889009,0.346797822 8.22601254,0.346797822 7.78872566,0.778310074 Z' id='arrow-tilted_svg__a'/%3E%3C/defs%3E%3Cg transform='rotate(-45 13.995 5.045)' fill='none' fill-rule='evenodd'%3E%3Cline x1='12.5' y1='6.5' x2='.5' y2='6.5' stroke='%23fff' stroke-width='2.2' stroke-linecap='round'/%3E%3Cuse fill='%23fff' xlink:href='%23arrow-tilted_svg__a'/%3E%3C/g%3E%3C/svg%3E")`,
    }
}))

export const StyledSwiper = styled(Swiper)(({theme}) => ({
    maxWidth: "100%",
    marginBottom: 4,
    position: "relative",
    overflow: "visible",
    "& .swiper-wrapper": {
        paddingBottom: 80,
        // alignItems: "center",
    },
    "& .swiper-slide": {
        padding: "18px 24px 30px 24px",
        boxSizing: "border-box",
        boxShadow: "0 10px 20px 0 rgba(0,0,0,0.08)",
        borderRadius: 10,
        background: "#fff",
        height: "auto",
        "& span": {
            fontFamily: theme.fonts.filson,
            fontSize: theme.fonts.pxToRem(14),
            lineHeight: 2.15,
            opacity: .5
        },
        "& h3": {
            fontFamily: theme.fonts.filson,
            fontSize: theme.fonts.pxToRem(24),
            fontWeight: theme.fontWeights.bold,
            [theme.breakpoint.down("md")]: {
                fontSize: theme.fonts.pxToRem(20),
            }
        }
    },
    "& .swiper-pagination-bullet": {
        width: 12,
        height: 12,
        background: "#FCCDC5",
        opacity: 1,
        marginLeft: "0 !important",
        marginRight: "12px !Important",
        "&:last-child": {
            marginRight: "0 !important"
        }
    },
    "& .swiper-pagination-bullet-active": {
        background: theme.colors.primary,
    },
    "&::after": {
        content: "''",
        position: "absolute",
        left: "-100vw",
        right: 0,
        bottom: "-90px",
        height: "80%",
        width: "300vw",
        background: "#fff"
    }
}))

export const Next = styled("div")(({theme}) => ({
    position: "absolute",
    bottom: -5,
    right: 40,
    cursor: "pointer",
    width: 46,
    height: 46,
    [theme.breakpoint.down("lg")]: {
    },
    [theme.breakpoint.down("md")]: {
        right: 0
    },
    zIndex: 20,
    "&.swiper-button-disabled": {
        display: "none"
    }
}))

export const Prev = styled(Next)(({theme}) => ({
    left: 40,
    right: 0,
    [theme.breakpoint.down("md")]: {
        left: 0
    }
}))

export const ProductOverviewWrapper = styled("div")(({theme}) => ({
    width: "100%",
    backgroundColor: theme.colors.primary,
    position: "relative",
    overflow: "hidden",
    marginBottom: -1,
}))

export const TextWrapper = styled("div")(({theme}) => ({
    maxWidth: 750,
    color: "#fff",
    textAlign: "left",
    marginTop: 40,
    marginBottom: 40,
    "& h2": {
        marginBottom: 20
    },
    "& p": {
        fontFamily: theme.fonts.filson,
        fontSize: theme.fonts.pxToRem(18),
        lineHeight: "30px"
    },
    [theme.breakpoint.down("lg")]: {
        "& p": {
            fontSize: theme.fonts.pxToRem(18),
            lineHeight: "30px"
        },
    },
    [theme.breakpoint.down("md")]: {
        marginTop: 54,
        marginBottom: 60,
        "& p": {
            fontSize: theme.fonts.pxToRem(16),
            lineHeight: "26px"
        },
        textAlign: "left"
    }
}))
