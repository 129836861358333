import styled from "@emotion/styled"
import Container from "@components/Container";

export const StyledContainer = styled(Container)(({theme}) => ({
    position: "relative",
    paddingTop: "0 !important",
    paddingBottom: "0 !important",
    alignItems: "center",
    display: "flex",
    height: 70,
    [theme.breakpoint.down("lg")]: {
        paddingLeft: 20,
        paddingRight: 20
    }
}))

export const MenuWrapper = styled("div", {
    shouldForwardProp: prop => prop !== "isSticky" && prop !== "extraOffset",
})<{ isSticky: boolean, extraOffset: boolean}>(({theme, isSticky, extraOffset}) => ({
    width: "100%",
    height: isSticky ? 70 : 0,
    backgroundColor: "#fff",
    position: "fixed",
    top: extraOffset ? 70 : 0,
    transform: isSticky ? "translateY(0)" : extraOffset ? "translateY(-140px)" : "translateY(-70px)",
    display: "block",
    transition: "all 0.5s ease",
    zIndex: 1100,
    [theme.breakpoint.down("lg")]: {
        top: extraOffset ? 120 : 0,
        transform: isSticky ? "translateY(0)" : extraOffset ? "translateY(-190px)" : "translateY(-70px)",
    }
}))

export const CloseButtonWrapper = styled("div", {
    shouldForwardProp: prop => prop !== "isOpen",
})<{ isOpen: boolean }>(({theme, isOpen}) => ({
    display: isOpen ? "block" : "none",
    marginLeft: "auto"
}))

export const ImageWrapper = styled.div(({theme}) => ({
    width: "fit-content",
    marginRight: 80,
    [theme.breakpoint.down("lg")]: {
        marginRight: 20
    }
}))

export const ActiveBullet = styled.div(({theme}) => ({
    width: 6,
    height: 6,
    marginTop: 6,
    borderRadius: "50%",
    backgroundColor: "#EEA995",
    display: "none"
}))

export const LinkWrapper = styled.div(({theme}) => ({
    fontSize: theme.fonts.pxToRem(14),
    lineHeight: "19px",
    display: "flex",
    alignItems: "center",
    fontFamily: theme.fonts.filson,
    "& a": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginRight: 30,
        "&.active": {
            fontWeight: theme.fontWeights.bold,
            [`${ActiveBullet}`]: {
                display: "block"
            }
        }
    },
    [theme.breakpoint.down("md")]: {
        display: "none"
    }
}))

export const ButtonWrapper = styled.div(({theme}) => ({
    marginLeft: "auto",
    height: 38,
    [theme.breakpoint.down("md")]: {
        display: "none"
    }
}))

export const HamburgerMenu = styled("div", {
    shouldForwardProp: (prop) => prop !== "isOpen"
})<{ isOpen: boolean }>(({theme, isOpen}) => ({
    marginLeft: "auto",
    fontSize: theme.fonts.pxToRem(12),
    lineHeight: "17px",
    cursor: "pointer",
    fontFamily: theme.fonts.filson,
    display: "none",
    [theme.breakpoint.down("md")]: {
        display: isOpen ? "none" : "flex"
    }
}))

export const SpanMenu = styled.div(({theme}) => ({
    display: "none",
    [theme.breakpoint.down("md")]: {
        marginRight: 8,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    "& span": {
        marginBottom: 3,
        display: "block",
        width: 12,
        height: 2,
        background: theme.colors.primary,
        borderRadius: 2,
        "&:last-child": {
            marginBottom: 0
        },
        "&:not(:last-child,:first-child)": {
            width: 8
        }
    }
}))

