import styled from "@emotion/styled"
import Container from "@components/Container"

export const ImageWrapper = styled("div")(({theme}) => ({
    maxWidth: 615,
    zIndex: 1,
    "& img": {
        width: "100%",
        height: "100%"
    },
    "&::after": {
        content: "''",
        position: "absolute",
        left: "-100vw",
        right: 0,
        bottom: 0,
        height: "27.5%",
        width: "300vw",
        background: theme.colors.tertiary,
        zIndex: -1,
        [theme.breakpoint.down("lg")]: {
            height: "26%",
        },
        [theme.breakpoint.down("md")]: {
            height: "18%",
        }
    }
}))

export const TextWithImageContainer = styled(Container)(({theme}) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    paddingTop: "0px !important",
    marginTop: 100
}))

export const TextWrapper = styled("div")(({theme}) => ({
    maxWidth: 750,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    color: "#fff",
    p: {
        fontSize: theme.fonts.pxToRem(18),
        lineHeight: "30px",
        margin: 0,
        marginBottom: 80,
        fontFamily: theme.fonts.filson,
        [theme.breakpoint.down("md")]: {
            marginBottom: 40,
            fontSize: theme.fonts.pxToRem(16),
            lineHeight: "26px",
        }
    },
    "h2, h3": {
        color: theme.colors.tertiary,
        [theme.breakpoint.down("md")]: {
            marginBottom: 20
        }
    },
    [theme.breakpoint.down("md")]: {
        textAlign: "left",
        alignItems: "flex-start"
    }
}))

export const TextWithImageWrapper = styled.div(({theme}) => ({
    overflow: "hidden",
    "&:first-child": {
        [`${ImageWrapper}`]: {
            "&::after": {
                background: "#9DC5BC",
            }
        },
        [`${TextWithImageContainer}`]: {
            paddingBottom: 0,
        },
        [`${TextWrapper}`]: {
            paddingTop: 70
        }
    }
}))
