const messages = {
    langSwitchText: "Wählen Sie ein anderes Land oder eine andere Region um spezifische Inhalte angezeigt zu bekommen.",
    menu: "Menü",
    collapseFeatures: "Weniger anzeigen",
    seeFeatures: "Alle anzeigen",
    continue: "Fortfahren",
    close: "Schließen"
}

export default messages
