import * as React from "react"

const ArrowRightIcon = () => <svg width="11" height="11" viewBox="0 0 11 11" xmlns="http://www.w3.org/2000/svg">
    <g transform="translate(1.678 1.678)" stroke="#FFF" strokeWidth="2.5" fill="none" fillRule="evenodd"
       strokeLinecap="round">
        <line x1="-1.104" y1="3.872" x2="8.849" y2="3.872" transform="rotate(45 3.872 3.872)"/>
        <line x1="-1.104" y1="3.872" x2="8.849" y2="3.872" transform="scale(-1 1) rotate(45 0 -5.476)"/>
    </g>
</svg>

export default ArrowRightIcon
