import styled from "@emotion/styled"

export const Container = styled("div", {
    shouldForwardProp: (prop) => prop !== 'open'
})<{ open: boolean }>(({theme, open}) => ({
    boxSizing: "border-box",
    position: "fixed",
    zIndex: 1400,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    pointerEvents: "all",
    display: "flex",
    visibility: open ? "visible" : "hidden",
    transition: open ? "visibility 0.1s linear 0s" : "visibility 0.1s linear 0.2s",
    overflowX: "hidden",
    overflowY: "auto",
    [`${Wrapper}`]: {
        opacity: open ? 1 : 0,
        transform: `translate3d(0, ${open ? 0 : -100}px, 0)`,
        transition: "opacity 0.2s ease, transform 0.2s ease-out",
    }
}))

export const Blur = styled("div", {
    shouldForwardProp: (prop) => prop !== 'open'
})<{ open: boolean }>(({theme, open}) => ({
    boxSizing: "border-box",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "#232323",
    position: "fixed",
    zIndex: 1300,
    opacity: open ? 0.4 : 0,
    visibility: open ? "visible" : "hidden",
    transition: open ? "opacity 0.2s ease, visibility 0.1s linear 0s" : "opacity 0.2s ease, visibility 0.1s linear 0.2s",
}))

export const ModalWrapper = styled("div", {
    shouldForwardProp: prop => prop !== "small"
})<{ small?: boolean }>(({theme, small}) => ({
    maxWidth: small ? 380 : 880,
    boxSizing: "border-box",
    color: "black",
    backgroundColor: "#fff",
    marginLeft: "auto",
    marginRight: "auto",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    width: "100%"
}))

export const Wrapper = styled("div", {
    shouldForwardProp: prop => prop !== "mobileFullScreen"
})<{ mobileFullScreen: boolean }>(({theme, mobileFullScreen}) => ({
    marginLeft: 30,
    marginRight: 30,
    padding: "30px 0",
    width: "100%",
    position: "relative",
    marginTop: "auto",
    marginBottom: "auto",
    display: "flex",
    [theme.breakpoint.down("sm")]: {
        padding: 0,
        margin: mobileFullScreen ? 0 : 20,
        background: mobileFullScreen ? "white" : "",
    }
}))

export const CloseButton = styled("button")(({theme}) => ({
    marginLeft: "auto",
    background: "white",
    transition: "background 0.2s ease",
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 40,
    height: 40,
    margin: 0,
    borderRadius: "50%",
    cursor: "pointer",
    position: "absolute",
    zIndex: 10,
    top: 0,
    right: 0,
    transform: "translate(50%, -50%)",
    "& svg": {
        width: 24,
        height: 24,
        transition: "color 0.2s ease",
    },
    "&:hover": {
        backgroundColor: theme.colors.tertiary,
        "& svg": {
            color: "#fff",
        }
    },
    [theme.breakpoint.down("md")]: {
        top: 10,
        right: 10,
        transform: "none",
    }
}))


export const Column = styled("div")(({theme}) => ({
    margin: "0 40px",
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    paddingBottom: 50,
    [theme.breakpoint.down("md")]: {
        margin: "0 20px",
        paddingBottom: 30,
    },
    "& h2": {
        fontFamily: theme.fonts.body,
        marginBottom: 32,
        textTransform: "uppercase",
        [theme.breakpoint.down("md")]: {
            marginBottom: 20,
        }
    },
    "& p": {
        fontWeight: theme.fontWeights.body,
        fontSize: theme.fonts.pxToRem(15),
        opacity: .8,
        [theme.breakpoint.down("md")]: {
            fontSize: theme.fonts.pxToRem(14),
        }
    }
}))

export const ButtonWrapper = styled("div")(({theme}) => ({
    margin: "0 40px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& button:first-child": {
        marginBottom: 10
    },
    "& button:last-child": {
        marginBottom: 46
    },
    "& button": {
        maxWidth: 375,
    },
    [theme.breakpoint.down("md")]: {
        margin: "0 20px",
        "& button:last-child": {
            margin: "0 20px 20px 20px"
        },
    }
}))

export const ModalContent = styled.div(({theme}) => ({
    padding: 40,
    [theme.breakpoint.down("md")]: {
        padding: "50px 30px 30px 30px",
    },
    [theme.breakpoint.down("xs")]: {
        padding: "50px 20px 20px 20px",
    }
}))