import * as React from "react"
import {Helmet} from "react-helmet"
import {graphql, useStaticQuery} from "gatsby"
import {SeoProps} from "./types"

const activeEnv =
    process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || "development"

const Seo: React.FC<SeoProps> = ({
                                     title,
                                     description = "",
                                     og_image,
                                     index = true,
    children,
    location,
    frontPage
                                 }) => {
    const locale = "en"
    const {site} = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        description
                        author
                        siteUrl
                        legalName
                    }
                }
            }
        `
    )
    const ldJson = {
        "@context": "https://schema.org/",
        "@type": "Organization",
        "name": site?.siteMetadata?.author,
        "legalName": site?.siteMetadata?.legalName,
        "url": site?.siteMetadata?.siteUrl,
        "description": site?.siteMetadata?.description,
        "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+31208943090",
            "contactType": "customer service"
        },
        "logo": `${site?.siteMetadata?.siteUrl}/images/logo.svg`,
        "sameAs": [],
        "address": {
            "@type": "PostalAddress",
            "streetAddress": "Barbara Strozzilaan 101",
            "addressLocality": "Amsterdam",
            "addressRegion": "North-Holland",
            "postalCode": "1083 HN",
            "addressCountry": "The Netherlands"
        }
    }
    const metaTitle = title || site.siteMetadata?.title
    const metaDescription = description || site.siteMetadata.description
    const og_props = og_image ? [
        <meta property="og:image" content={`${site.siteMetadata?.siteUrl}${og_image.url}`} />,
        <meta property="og:image:secure_url" content={`${site.siteMetadata?.siteUrl}${og_image.url}`} />,
        <meta property="og:image:width" content={`${og_image.width}`} />,
        <meta property="og:image:height" content={`${og_image.height}`} />
    ] : []
    return (
        <Helmet
            htmlAttributes={{
                lang: locale,
            }}
            title={metaTitle}
        >
            <script type="application/ld+json">
                {JSON.stringify(ldJson)}
            </script>
            {frontPage &&
            <script type="application/ld+json">
                {JSON.stringify({
                    "@context": "http://schema.org",
                    "@type": "WebSite",
                    "name": site?.siteMetadata?.author,
                    "url": site?.siteMetadata?.siteUrl,
                })}
            </script>
            }
            {(index === false || activeEnv !== "production") && <meta name="robots" content="noindex"/>}
            <meta name="description" content={metaDescription} />
            <link rel="canonical" href={`${site.siteMetadata?.siteUrl}${location?.pathname}`} />
            <meta property="og:locale" content={locale} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={metaTitle} />
            <meta property="og:description" content={metaDescription} />
            <meta property="og:url" content={site.siteMetadata?.siteUrl} />
            <meta property="og:site_name" content={site.siteMetadata?.author} />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:title" content={metaTitle} />
            <meta name="twitter:description" content={metaDescription} />
            {[...og_props]}
            {children}
        </Helmet>
    )
}

export default Seo
