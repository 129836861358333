import * as React from "react"
import {GatsbyImage} from "gatsby-plugin-image"
import UspBlock from "@components/UspBlock";

const StrapiUspBlock: React.FC = (props: any) => {
    const processedImages = props?.contentImages.map((image: any) => image?.uspBlockImage?.childImageSharp?.gatsbyImageData)

    const uspList = props.uspList.map((usp: any, index: number) => {
        if (usp?.imageOrText?.image) {
            usp.imageOrText.image =
                <GatsbyImage image={processedImages[index]}
                             alt={props.uspList[index].imageOrText.image.alternativeText || ""}/>
        }
        return usp
    })
    return <UspBlock uspList={uspList}/>
}

export default StrapiUspBlock