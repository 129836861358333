import * as React from "react"
import {useEffect} from "react"
import {UnstyledButton} from "./styles"
import {HubspotButtonProps} from "./types"

const HubspotButton: React.FC<HubspotButtonProps> = ({
                                                         portalId,
                                                         ctaId,
                                                         variant,
                                                         children
                                                     }) => {

    useEffect(() => {
        if (typeof window?.hbspt?.cta?.load === "function") {
            window.hbspt.cta.load(portalId, ctaId, {"useNewLoader": "true"})
        }
    }, [])

    return <UnstyledButton className={variant} id={`hs-cta-wrapper-${ctaId}`}>
            <span className={`hs-cta-node hs-cta-${ctaId}`} id={`hs-cta-${ctaId}`}>
              <a
                 href={`https://cta-redirect.hubspot.com/cta/redirect/${portalId}/${ctaId}`}>{children}</a>
            </span>
    </UnstyledButton>
}

export default HubspotButton
