import * as React from "react"
import {ImageWrapper, TextWithImageContainer, TextWrapper, TextWithImageWrapper} from "./styles"
import {TextWithImageProps} from "./types"

const TextWithImage: React.FC<TextWithImageProps> = ({content, image}) => {
    return <TextWithImageWrapper>
        <TextWithImageContainer>
                <TextWrapper>
                    <div dangerouslySetInnerHTML={{__html: content}}/>
                </TextWrapper>
                <ImageWrapper>{image}</ImageWrapper>
        </TextWithImageContainer>
    </TextWithImageWrapper>
}

export default TextWithImage
