const messages = {
    langSwitchText: "Choose another country or region to see content specific to your location.",
    menu: "Menu",
    collapseFeatures: "Collapse features",
    seeFeatures: "See all features",
    continue: "Continue",
    close: "Close"
}

export default messages
