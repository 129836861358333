import * as React from "react"
import {HeroWrapper, OuterHeroContainer, BackgroundWrapper, ImageWrapper, HeroContainer, Text} from "./styles"
import {HeroProps} from "./types"

const Hero: React.FC<HeroProps> = ({backgroundImage, image, text}) => {

    return <OuterHeroContainer>
        <BackgroundWrapper>
            {backgroundImage}
        </BackgroundWrapper>
        <HeroContainer>
        <HeroWrapper>
            <Text dangerouslySetInnerHTML={{__html: String(text)}}/>
            <ImageWrapper>
                {image}
            </ImageWrapper>
        </HeroWrapper>
    </HeroContainer>
    </OuterHeroContainer>
}

export default Hero
