import styled from "@emotion/styled"
import Container from "@components/Container";

export const ComparisonWrapper = styled.div(({theme}) => ({
    backgroundColor: theme.colors.tertiary
}))

export const StyledContainer = styled(Container)(({theme}) => ({
    position: "relative",
    [theme.breakpoint.down("md")]: {
    paddingBottom: 70
}
}))

export const ButtonWrapper = styled("div")(({theme}) => ({
    [theme.breakpoint.down("md")]: {
        width: "100%",
    }
}))

export const TextWrapper = styled("div")(({theme}) => ({
    marginBottom: 20,
    "& h2": {
        marginBottom: 20
    },
    "& p": {
        fontSize: theme.fonts.pxToRem(18),
        lineHeight: "30px",
        fontFamily: theme.fonts.filson,
        color: theme.colors.secondary,
        marginBottom: 50,
        [theme.breakpoint.down("md")]: {
            fontSize: theme.fonts.pxToRem(16),
            lineHeight: "26px",
        }
    }
}))

export const CtaWrapper = styled("div")(({theme}) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 40,
    "& p": {
        fontSize: theme.fonts.pxToRem(26),
        lineHeight: "32px",
        fontWeight: theme.fontWeights.bold,
        fontFamily: theme.fonts.filson,
        marginBottom: 0,
        marginRight: 40
    },
    [theme.breakpoint.down("md")]: {
        marginTop: 45,
        flexDirection: "column",
        "& p": {
            marginRight: 0,
            marginBottom: 20
        }
    }
}))

export const Table = styled("div")(({theme}) => ({
    width: "100%",
    background: "#fff",
    borderRadius: 10,
    overflow: "hidden"
}))

export const TableHeader = styled("div")(({theme}) => ({
    display: "flex",
    backgroundColor: "#FFF4F4",
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    fontSize: theme.fonts.pxToRem(24),
    lineHeight: "29px",
    fontFamily: theme.fonts.filson,
    [`${TableColumn}, ${TableWideColumn}`]: {
        paddingTop: 40,
    },
    [theme.breakpoint.down("lg")]: {
        fontSize: theme.fonts.pxToRem(16),
        lineHeight: "19px",
        "& p": {
            writingMode: "vertical-rl",
            transform: "rotate(-180deg)",
            whiteSpace: "nowrap",
            lineHeight: 1,
        }
    }
}))

export const SpanWrapper = styled("div", {
    shouldForwardProp: (prop) => prop !== "isOpen"
})<{ isOpen: boolean }>(({theme, isOpen}) => ({
    position: "relative",
    backgroundColor: theme.colors.primary,
    width: 26,
    height: 26,
    marginRight: 20,
    marginLeft: 20,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& span": {
        position: "absolute",
        transition: "all .2s",
        background: "#fff",
        pointerEvents: "none",
        width: 4,
        height: 14,
        display: "block",
        transform: isOpen ? "rotate(90deg) translate(0, 0);" : "none",
        "&:first-child": {
            transform: "rotate(90deg) translate(0, 0);"
        },
    }
}))

export const TableRow = styled("div", {
    shouldForwardProp: prop => prop !== "isShown"
})<{ isShown: boolean }>(({theme, isShown}) => ({
    display: "flex",
    backgroundColor: "#fff",
    color: theme.colors.secondary,
    fontSize: theme.fonts.pxToRem(18),
    lineHeight: "23px",
    [theme.breakpoint.down("lg")]: {
        fontSize: theme.fonts.pxToRem(14),
        lineHeight: "18px",
    },
    height: "auto",
    fontFamily: theme.fonts.filson,
    borderBottom: "1px solid rgb(151, 151, 151, .2)",
    "&:last-child": {
        border: "none"
    },
    "&.hidden": {
        display: isShown ? "block" : "none",
    }
}))

export const TableFooter = styled("div")(({theme}) => ({
    background: "rgb(0, 15, 99, .1)",
    padding: 40,
    [theme.breakpoint.down("lg")]: {
        padding: 20,
    },
}))

export const TableBody = styled("div")(({theme}) => ({
    overflow: "hidden",
}))

export const FooterWrapper = styled("div")(({theme}) => ({
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    fontSize: theme.fonts.pxToRem(18),
    lineHeight: "23px",
    position: "relative",
    left: "40%",
    fontFamily: theme.fonts.filson,
    "& p": {
        fontWeight: theme.fontWeights.bold
    },
    [theme.breakpoint.down("lg")]: {
        left: 0,
        justifyContent: "center"
    },
}))

export const TableColumn = styled("div")(({theme}) => ({
    width: "20%",
    boxSizing: "border-box",
    borderRight: "1px solid rgb(151, 151, 151, .2)",
    padding: 40,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:last-child": {
        border: "none"
    },
    "& > *": {
      maxWidth: "100%"
    },
    "& img": {
      maxWidth: "100%",
      height: "auto",
    },
    [theme.breakpoint.down("lg")]: {
        padding: 20,
    },
    [theme.breakpoint.down("md")]: {
        padding: 15,
        width: "15%",
    }
}))

export const ImageColumn = styled(TableColumn)(({theme}) => ({
    "& img": {
        [theme.breakpoint.down("md")]: {
            width: 27,
            height: 25
        }
    }
}))

export const TableWideColumn = styled(TableColumn)(({theme}) => ({
    width: "40%",
    justifyContent: "flex-start",
    [theme.breakpoint.down("md")]: {
        width: "55%",
    }
}))
