import * as React from "react"
import {
    CtaWrapper,
    ImageWrapper,
    Row,
    StyledContainer,
    StyledText,
    TextWrapper,
    TopText,
    UspIconBlockWrapper,
    UspItem,
    UspTextWrapper,
    ButtonWrapper
} from "./styles"
import {UspIconBlockProps} from "./types"
import Button from "@components/Button";

const UspIconBlock: React.FC<UspIconBlockProps> = ({uspIconList, content, bottomCta}) => {
    return <UspIconBlockWrapper>
        <StyledContainer>
            <TextWrapper>
                <div dangerouslySetInnerHTML={{__html: content}}/>
            </TextWrapper>
            <Row>
                {uspIconList && uspIconList.map((usp, index) => (
                    <UspItem key={index}>
                        <ImageWrapper>{usp.image}</ImageWrapper>
                        <UspTextWrapper>
                            <TopText dangerouslySetInnerHTML={{__html: usp.title}} />
                            <StyledText dangerouslySetInnerHTML={{__html: usp.text}} />
                        </UspTextWrapper>
                    </UspItem>
                ))}
            </Row>
            <CtaWrapper>
                <p>{bottomCta.title}</p>
                <ButtonWrapper>
                    <Button url={bottomCta.button.link}
                            variant={bottomCta.button.variant}>{bottomCta.button.label}</Button>
                </ButtonWrapper>
            </CtaWrapper>
        </StyledContainer>
    </UspIconBlockWrapper>
}

export default UspIconBlock
