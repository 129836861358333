import * as React from "react"
import TextWithImage from "@components/TextWithImage";
import {GatsbyImage} from "gatsby-plugin-image";

const StrapiTextWithImage: React.FC = (props: any) => {
    const processedImage = props?.contentImages ? props.contentImages.map((image: any) => image?.textWithImageImage?.childImageSharp?.gatsbyImageData).find((entry: any) => entry !== null) : null
    const processedSvg = props?.contentImages ? props.contentImages.map((image: any) => image?.textWithImageImage?.publicURL).find((entry: any) => entry !== undefined) : null

    return <TextWithImage
        content={props.content}
        image={(processedImage !== null && processedImage !== undefined) ?
            <GatsbyImage image={processedImage} alt={props.image.alternativeText || ""}/> :
            processedSvg ? <img src={processedSvg} alt={props.image.alternativeText || ""}/> : undefined}
        />
}

export default StrapiTextWithImage