import styled from "@emotion/styled"
import Container from "@components/Container"

export const StyledContainer = styled(Container)(({theme}) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    paddingTop: "40px !important",
    [theme.breakpoint.down("md")]: {
       paddingBottom: 120
    }
}))

export const UspIconBlockWrapper = styled("div")(({theme}) => ({
    width: "100%",
    background: theme.colors.tertiary
}))

export const Row = styled("div")(({theme}) => ({
    display: "flex",
    flexWrap: "wrap",
    position: "relative",
    width: "100%"
}))

export const UspTextWrapper = styled("div")(({theme}) => ({}))

export const ButtonWrapper = styled("div")(({theme}) => ({
    [theme.breakpoint.down("md")]: {
        width: "100%",
    }
}))

export const TextWrapper = styled("div")(({theme}) => ({
    maxWidth: 750,
    color: theme.colors.primary,
    textAlign: "center",
    marginBottom: 100,
    "& h2": {
        marginBottom: 20
    },
    "& p": {
        fontFamily: theme.fonts.filson,
        fontSize: theme.fonts.pxToRem(18),
        color: theme.colors.secondary,
    },
    [theme.breakpoint.down("lg")]: {
        marginBottom: 80,
    },
    [theme.breakpoint.down("md")]: {
        marginBottom: 50,
        "& p": {
            fontSize: theme.fonts.pxToRem(16)
        },
        textAlign: "left"
    }
}))

export const UspItem = styled("div")(({theme}) => ({
    color: "#fff",
    width: "33.3333%",
    flex: "1 1 33.3333%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 48,
    [theme.breakpoint.down("lg")]: {
        width: "50%",
        flex: "1 1 50%"
    },
    [theme.breakpoint.down("md")]: {
        width: "100%",
        flex: "1 1 100%",
        "&:last-child": {
            marginBottom: 0
        }
    }
}))

export const CtaWrapper = styled("div")(({theme}) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 40,
    "& p": {
        fontSize: theme.fonts.pxToRem(26),
        lineHeight: "32px",
        fontWeight: theme.fontWeights.bold,
        fontFamily: theme.fonts.filson,
        marginBottom: 0,
        marginRight: 40,
    },
    [theme.breakpoint.down("md")]: {
        marginTop: 60,
        flexDirection: "column",
        "& p": {
            marginRight: 0,
            marginBottom: 20
        }
    }
}))

export const ImageWrapper = styled("div")(({theme}) => ({
    color: "#fff",
    marginBottom: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
        minWidth: 115,
        maxWidth: "100%",
        maxHeight: "100%",
        width: "auto",
        height: "auto",
    },
    [theme.breakpoint.down("md")]: {
        "& img": {
            maxWidth: "80%",
        }
    },
    [theme.breakpoint.up("md")]: {
        height: 190,
    }
}))

export const TopText = styled("div")(({theme}) => ({
    fontSize: theme.fonts.pxToRem(26),
    color: theme.colors.primary,
    textAlign: "center",
    fontFamily: theme.fonts.filson,
    fontWeight: theme.fontWeights.medium,
    marginBottom: 10,
    [theme.breakpoint.down("md")]: {
        fontSize: theme.fonts.pxToRem(20),
    }
}))

export const StyledText = styled("div")(({theme}) => ({
    fontFamily: theme.fonts.filson,
    fontSize: theme.fonts.pxToRem(16),
    lineHeight: "30px",
    textAlign: "center",
    color: theme.colors.secondary
}))
