import {useCallback, useEffect, useState} from "react"

const listenerOptions: AddEventListenerOptions & EventListenerOptions = {passive: true}

const useSticky = () => {
    const [isSticky, setIsSticky] = useState(false)

    const listenScrollEvent = useCallback((menuTimer) => {
        if(window.scrollY > 50) {
            setIsSticky(true)
            clearTimeout(menuTimer)
            window.removeEventListener("scroll", listenScrollEvent, listenerOptions)
        }
    }, [])

    useEffect(() => {

        const menuTimer = setTimeout(() => {
            setIsSticky(true)
            window.removeEventListener("scroll", listenScrollEvent, listenerOptions)
        }, 3000);

        window.addEventListener("scroll", () => listenScrollEvent(menuTimer), listenerOptions)
        if (isSticky === null) {
            listenScrollEvent(menuTimer)
        }

        return function cleanup() {
            clearTimeout(menuTimer)
            window.removeEventListener("scroll", listenScrollEvent, listenerOptions)
        }
    }, [])

    return isSticky
}

export default useSticky