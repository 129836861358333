import styled from "@emotion/styled"
import Container from "@components/Container";

export const StyledContainer = styled(Container)(({theme}) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    position: "relative",
    zIndex: 5,
    paddingBottom: "0 !important"
}))

export const CtaImageWrapper = styled("div")(({theme}) => ({
    position: "absolute",
    top: -16,
    right: 0,
    padding: "inherit",
    [theme.breakpoint.down("xl")]: {
        display: "none"
    }
}))

export const FormOuterWrapper = styled("div")(({theme}) => ({
    overflow: "hidden",
    position: "relative",
    backgroundColor: "#F4CFC6",
    marginBottom: -1,
}))

export const CtaImageMobileWrapper = styled("div")(({theme}) => ({
    position: "absolute",
    top: -16,
    right: 0,
    zIndex: 20,
    padding: "inherit",
    display: "none",
    [theme.breakpoint.down("xl")]: {
        display: "block",
        marginLeft: "auto",
        paddingTop: 0,
        top: -50
    },
    [theme.breakpoint.down("md")]: {
        top: -16
    }
}))

export const StyledFormContainer = styled(StyledContainer)<{noCtaImage?: boolean}>(({theme, noCtaImage}) => ({
    zIndex: 10,
    paddingBottom: "300px !important",
    [theme.breakpoint.down("lg")]: {
        paddingTop: noCtaImage ? 60 : 100,
        top: 0,
        bottom: "auto"
    },
    [theme.breakpoint.down("md")]: {
        paddingTop: noCtaImage ? 30 : 150,
        top: 0,
        bottom: "auto"
    }
}))


export const BackgroundWrapper = styled("div")(({theme}) => ({
    position: "absolute",
    overflow: "visible",
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1,
    width: "100%",
    pointerEvents: "none",
    userSelect: "none",
    // padding: "inherit",
    "& img": {
        display: "block",
        width: "100%",
        objectFit: "cover",
        [theme.breakpoint.down("md")]: {
            width: 740
        }
    }
}))

export const Wrapper = styled("div")(({theme}) => ({
    maxWidth: 750,
    zIndex: 5,
    display: "flex",
    marginLeft: "auto",
    marginRight: "auto",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    color: theme.colors.primary,
    [theme.breakpoint.down("xl")]: {
        marginBottom: 40,
    },
    [theme.breakpoint.down("lg")]: {
        textAlign: "left",
        alignItems: "flex-start"
    },
    "h2": {
        marginBottom: 24
    },
    "p": {
        fontSize: theme.fonts.pxToRem(18),
        margin: 0,
        color: theme.colors.secondary,
        fontFamily: theme.fonts.filson,
        marginBottom: 30,
        [theme.breakpoint.down("md")]: {
            fontSize: theme.fonts.pxToRem(16),
            marginBottom: 20,
        }
    }
}))

export const FormWrapper = styled("div")(({theme}) => ({
    display: "flex",
    position: "relative",
    [theme.breakpoint.down("lg")]: {
        flexDirection: "column"
    }
}))

export const Column = styled("div")(({theme}) => ({
    boxSizing: "border-box",
    display: "flex",
    zIndex: 5,
    flexDirection: "column",
    // justifyContent: "space-between",
    width: "50%",
    flex: "1 1 50%",
    [theme.breakpoint.down("md")]: {
        marginBottom: 64
    },
    "&:first-child": {
        backgroundColor: "#fff",
        borderRadius: 10,
        minHeight: 400
    },
    "&:last-child": {
        padding: 40,
        [theme.breakpoint.down("md")]: {
            padding: "30px 20px",
            paddingTop: 0
        }
    },
    [theme.breakpoint.down("lg")]: {
        width: "100%",
        flex: "1 1 100%",
    }
}))

export const TextBlock = styled("div")(({theme}) => ({
    fontFamily: theme.fonts.filson,
    fontSize: theme.fonts.pxToRem(16),
    fontWeight: theme.fontWeights.body,
    lineHeight: "30px",
    "& div": {
        color: theme.colors.secondary
    },
    "&:first-child": {
        marginBottom: 30
    }
}))

export const Row = styled("div")(({theme}) => ({
    display: "flex",
    marginBottom: 20,
    alignItems: 'center',
    "& h3": {
        color: theme.colors.primary,
        fontFamily: theme.fonts.filson,
        fontSize: theme.fonts.pxToRem(26),
        fontWeight: theme.fontWeights.bold,
        lineHeight: "32px",
        [theme.breakpoint.down("md")]: {
            fontSize: theme.fonts.pxToRem(22),
            lineHeight: "28px",
        }
    }
}))

export const Number = styled("span")(({theme}) => ({
    marginRight: 20,
    minWidth: 40,
    fontFamily: theme.fonts.filson,
    fontSize: theme.fonts.pxToRem(20),
    fontWeight: theme.fontWeights.body,
    lineHeight: "24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 40,
    height: 40,
    borderRadius: "50%",
    color: "#fff",
    background: theme.colors.primary,
    [theme.breakpoint.down("md")]: {
        width: 30,
        height: 30,
        minWidth: 30,
        minHeight: 30,
    }
}))



