import * as React from "react"
import {GatsbyImage} from "gatsby-plugin-image"
import ProductHighlights from "@components/ProductHighlights"

const processImages = (imageData: any, index: number) => ({
    ...imageData,
    images: {
        ...imageData.images,
        fallback: {
            ...imageData.images.fallback,
            src: `${imageData.images.fallback.src}?v=${index}`
        }
    }
})

const StrapiProductHighlights: React.FC = (props: any) => {
    const processedImages = props?.contentImages
        .map((image: any) => image?.productHighLightImage?.childImageSharp?.gatsbyImageData)
        .filter((entry: any) => entry !== undefined)
        .map(processImages)
    const processedMobileImages = props?.contentImages
        .map((image: any) => image?.productHighLightMobileImage?.childImageSharp?.gatsbyImageData)
        .filter((entry: any) => entry !== undefined)
        .map(processImages)

    const slides = props.slides.map((slide: any, index: number) => {
        if (slide?.image) {
            slide.image =
                <GatsbyImage image={processedImages[index]}
                             alt={props.slides[index].image.alternativeText || ""}/>
        }
        return slide
    })

    const mobileSlides = props.mobileSlides.map((slide: any, index: number) => {
        if (slide?.image) {
            slide.image =
                <GatsbyImage image={processedMobileImages[index]}
                             alt={props.mobileSlides[index].image.alternativeText || ""}/>
        }
        return slide
    })

    return <ProductHighlights
        slides={slides}
        mobileSlides={mobileSlides}
        content={props.content}
    />
}

export default StrapiProductHighlights