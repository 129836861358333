import * as React from "react"
import {ContentContainer, Wrapper} from "./styles"
import {ContentProps} from "./types"

const Content: React.FC<ContentProps> = ({content}) => {
    return <ContentContainer>
        <Wrapper>
            <div dangerouslySetInnerHTML={{__html: content}}/>
        </Wrapper>
    </ContentContainer>
}

export default Content
