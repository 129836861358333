import * as React from "react"
import {ButtonWrapper, MenuContainer, MenuItem, MobileOverlay, StyledContainer} from "./styles"
import {MenuProps} from "./types"
import {useInView} from "react-intersection-observer";
import {Link} from "gatsby";
import Button from "@components/Button";
import {useEffect} from "react";
import {scrollLock} from "@utils/scrollLock"
import {moveToAnchor} from "@utils/moveToAnchor"

const MobileMenu: React.FC<MenuProps> = ({isMenuOpen, setIsMenuOpen, links, button}) => {

    useEffect(() => {
        if (isMenuOpen) {
            scrollLock.enable()
        } else {
            scrollLock.disable()
        }
    }, [isMenuOpen])

    const {label: buttonLabel, ...buttonProps} = button

    return <MenuContainer isOpen={isMenuOpen}>
            <StyledContainer isOpen={isMenuOpen}>
                {links && links.map((link, index) => {
                    const [ref, inView] = useInView({
                        triggerOnce: false,
                        threshold: 1
                    })

                    let linkProps

                    link.linkType === "anchor" ?
                        linkProps = {
                            onClick: (e) => {
                                e.preventDefault()
                                setIsMenuOpen(false)
                                moveToAnchor(link.link)
                            },
                            ref: ref,
                            to: link.link
                        }
                        :
                        linkProps = {
                            to: link.link
                        }

                    return <MenuItem key={index}>
                        <Link {...linkProps}>{link.label}</Link>
                    </MenuItem>
                })}
                <ButtonWrapper>
                    <Button {...buttonProps}>{buttonLabel}</Button>
                </ButtonWrapper>
            </StyledContainer>
        </MenuContainer>
}

export default MobileMenu