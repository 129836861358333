import * as React from "react"

const VinkjeIcon = () => <svg width="34" height="31" viewBox="0 0 34 31" xmlns="http://www.w3.org/2000/svg"
                                 xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
        <polygon id="vinkje_svg__a" points="0 0 34 0 34 30.9948212 0 30.9948212"/>
    </defs>
    <g fill="none" fillRule="evenodd">
        <mask id="vinkje_svg__b" fill="#fff">
            <use xlinkHref="#vinkje_svg__a"/>
        </mask>
        <path
            d="M-0.101583508,13.9152475 C1.8708296,13.583408 3.02380241,10.8575839 5.8935365,10.7695449 C6.023902,12.9332736 6.84842147,15.262922 7.31909172,17.3640076 C7.98954287,20.360721 8.9054875,22.4364107 9.24240613,23.5656807 C12.799522,19.3127179 18.7895628,14.1455034 22.8749129,10.2565482 C25.9715168,7.30893337 28.5906782,3.83308435 32.3374166,0 C36.1840454,2.04690768 32.4457723,6.284633 30.2600339,8.89871527 C26.7469376,13.0991933 23.3252664,17.4300368 19.4380042,21.0904292 C10.7289115,29.2882183 4.51369386,36.7630714 1.80649338,25.0081665 L-0.101583508,13.9152475"
            fill="#70AC8A" mask="url(#vinkje_svg__b)"/>
    </g>
</svg>

export default VinkjeIcon
