import * as React from "react"
import {useEffect, useState} from "react"
import {StyledContainer} from "./styles"
import {HubspotFormProps} from "./types"
import {Number, Row} from "@components/Form/styles";

const HubspotForm: React.FC<HubspotFormProps> = ({
                                                     title = "Apply for a trial",
                                                     portalId,
                                                     formId
                                                 }) => {

    const [formSubmitted, setFormSubmitted] = useState(false)

    const submitHandler = (event: any) => {
        if(event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmit') {
            setFormSubmitted(true)
        }
    }

    useEffect(() => {
        if (typeof window.hbspt?.forms?.create === "function") {
            window.hbspt.forms.create({
                portalId: portalId,
                formId: formId,
                target: '#form-holder',
                // onFormSubmitted: ()  => {
                //     console.log("test")
                // }
            });
        }

        window.addEventListener('message', submitHandler);
        return () => window.removeEventListener('message', submitHandler)
    }, [])

    return <StyledContainer>
        <Row><Number>{1}</Number><h3>{title}</h3></Row>
        <div id="form-holder"/>
        {formSubmitted && <img height="1" width="1" style={{display:"none"}} alt="" src="https://px.ads.linkedin.com/collect/?pid=1275276&conversionId=5780898&fmt=gif" />}
    </StyledContainer>
}

export default HubspotForm
