import styled from "@emotion/styled"
import Container from "@components/Container";

export const UspContainer = styled(Container)(({theme}) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    paddingBottom: 66,
    [theme.breakpoint.down("md")]: {
        paddingTop: 48
    }
}))

export const Row = styled("div")(({theme}) => ({
    display: "flex",
    alignItems: "end",
    position: "relative",
    width: "100%",
    flexWrap: "wrap",
    marginBottom: -48,
    justifyContent: "space-between",
    [theme.breakpoint.down("md")]: {
        justifyContent: "center",
        marginBottom: 0,
    }
}))

export const UspItem = styled("div", {
    shouldForwardProp: prop => prop !== "flipped"
})<{ flipped: boolean }>(({theme, flipped}) => ({
    color: "#fff",
    width: 240,
    display: "flex",
    flexDirection: flipped ? "column-reverse" : "column",
    alignItems: "center",
    marginBottom: 48,
    [theme.breakpoint.down("md")]: {
        marginBottom: 30,
    }
}))

export const ImageWrapper = styled("div", {
    shouldForwardProp: prop => prop !== "flipped"
})<{ flipped: boolean }>(({theme, flipped}) => ({
    color: "#fff",
    marginBottom: flipped ? 0 : 20,
    marginTop: flipped ? 20 : 0,
}))

export const TopText = styled("div", {
    shouldForwardProp: prop => prop !== "color"
})<{ color: string }>(({theme, color}) => ({
    fontSize: theme.fonts.pxToRem(80),
    color: color,
    lineHeight: "111px",
    textAlign: "center",
    [theme.breakpoint.down("md")]: {
        fontSize: theme.fonts.pxToRem(60),
        lineHeight: "83px",
    }
}))

export const StyledText = styled("div")(({theme}) => ({
    fontFamily: theme.fonts.filson,
    fontSize: theme.fonts.pxToRem(16),
    lineHeight: "26px",
    textAlign: "center"
}))
