import * as React from "react"
import {GatsbyImage} from "gatsby-plugin-image"
import ProductOverview from "@components/ProductOverview";

const StrapiProductOverview: React.FC = (props: any) => {
    const processedImages = props?.contentImages.map((image: any, index: number) => {
        if (image?.productOverviewImage?.childImageSharp?.gatsbyImageData) {
            return <GatsbyImage image={image.productOverviewImage.childImageSharp.gatsbyImageData}
                                alt={props.products[index].image.alternativeText || ""}/>
        } else if (image?.productOverviewImage?.publicURL) {
            return <img src={image.productOverviewImage.publicURL} alt={props.products[index].alternativeText || ""}/>
        } else {
            return {}
        }
    })

    const products = props.products.map((product: any, index: number) => {
        if (product?.image) {
            product.image = processedImages[index]
        }
        return product
    })

    return <ProductOverview
        products={products}
        content={props.content}
    />
}

export default StrapiProductOverview