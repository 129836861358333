import * as React from "react"
import {AnchorProps} from "./types";
import {StyledAnchor} from "@components/Anchor/styles";

const Anchor: React.FC<AnchorProps> = ({
                                                 anchor_link
                                             }) => {

    return <StyledAnchor className="anchor" id={anchor_link} />
}

export default Anchor