import * as React from "react"
import {graphql, PageProps} from "gatsby"
import MainLayout from "@layouts/MainLayout"
import Seo from "@components/Seo"
import components from "@components/Strapi/components"
import icons from "@components/Strapi/icons"
import {PageContextProps} from "../types";

const PageTemplate: React.FC<PageProps<GatsbyTypes.pageQuery, PageContextProps>> = ({data, location, pageContext}) => {
    const {strapiPages: page} = data
    const sections = page?.sections
    const images = page?.contentImages
    const locale = pageContext.locale
    const translations = pageContext.translations

    const seo = page?.seo
    // @ts-ignore
    const uploadUrl = pageContext?.uploadUrl
    return <MainLayout locale={locale} location={location} translations={translations} >
        <Seo
            title={seo?.meta_title || page?.title}
            description={seo?.meta_description}
            // TODO fix CMS load of image --> og_image={seo?.open_graph_image?.imageFile?.publicURL}
            index={seo?.index}
        />
        {sections && Array.from(sections).map((section: any, index) => {

            const Component = section?.strapi_component ? components[section.strapi_component] : null

            let imageProps = {}

            if (images?.[index]?.images?.length) {
                imageProps = {
                    contentImages: images?.[index]?.images,
                }
            }

            const componentProps = {...section, location, uploadUrl: uploadUrl, icons: icons, ...imageProps}

            return <React.Fragment key={index}>
                {Component
                    ? <Component {...componentProps} />
                    : <>Missing section for {section.strapi_component}</>}
            </React.Fragment>
        })}
    </MainLayout>
}

export default PageTemplate

export const pageQuery = graphql`
    query page($id: Int!) {
        strapiPages(strapiId: {eq: $id}) {
            title
            seo {
                index
                meta_description
                meta_title
            }
            sections
            contentImages {
                images {
                    heroImage {
                        childImageSharp {
                            gatsbyImageData(
                                layout: CONSTRAINED
                                placeholder: BLURRED
                                formats: [AUTO, WEBP, AVIF]
                            )
                        }
                        publicURL
                    }
                    heroBackgroundImage {
                        childImageSharp {
                            gatsbyImageData(
                                layout: CONSTRAINED
                                placeholder: BLURRED
                                formats: [AUTO, WEBP, AVIF]
                            )
                        }
                        publicURL
                    }
                    uspBlockImage {
                        childImageSharp {
                            gatsbyImageData(
                                layout: CONSTRAINED
                                placeholder: BLURRED
                                formats: [AUTO, WEBP, AVIF]
                            )
                        }
                        publicURL
                    }
                    quoteImage {
                        childImageSharp {
                            gatsbyImageData(
                                layout: CONSTRAINED
                                placeholder: BLURRED
                                formats: [AUTO, WEBP, AVIF]
                            )
                        }
                        publicURL
                    }
                    productHighLightImage {
                        childImageSharp {
                            gatsbyImageData(
                                layout: CONSTRAINED
                                placeholder: BLURRED
                                formats: [AUTO, WEBP, AVIF]
                            )
                        }
                        publicURL
                    }
                    productHighLightMobileImage {
                        childImageSharp {
                            gatsbyImageData(
                                layout: CONSTRAINED
                                placeholder: BLURRED
                                formats: [AUTO, WEBP, AVIF]
                            )
                        }
                        publicURL
                    }
                    comparisonImage {
                        childImageSharp {
                            gatsbyImageData(
                                layout: CONSTRAINED
                                placeholder: BLURRED
                                formats: [AUTO, WEBP, AVIF]
                            )
                        }
                        publicURL
                    }
                    productOverviewImage {
                        childImageSharp {
                            gatsbyImageData(
                                layout: CONSTRAINED
                                placeholder: BLURRED
                                formats: [AUTO, WEBP, AVIF]
                            )
                        }
                        publicURL
                    }
                    videoPosterImage {
                        childImageSharp {
                            gatsbyImageData(
                                layout: CONSTRAINED
                                placeholder: BLURRED
                                formats: [AUTO, WEBP, AVIF]
                            )
                        }
                        publicURL
                    }
                    textWithImageImage {
                        childImageSharp {
                            gatsbyImageData(
                                layout: CONSTRAINED
                                placeholder: BLURRED
                                formats: [AUTO, WEBP, AVIF]
                            )
                        }
                        publicURL
                    }
                    uspIconBlockImage {
                        childImageSharp {
                            gatsbyImageData(
                                layout: CONSTRAINED
                                placeholder: BLURRED
                                formats: [AUTO, WEBP, AVIF]
                            )
                        }
                        publicURL
                    }
                    comingSoonImage {
                        childImageSharp {
                            gatsbyImageData(
                                layout: CONSTRAINED
                                placeholder: BLURRED
                                formats: [AUTO, WEBP, AVIF]
                            )
                        }
                        publicURL
                    }
                    comingSoonBackgroundImage {
                        childImageSharp {
                            gatsbyImageData(
                                layout: CONSTRAINED
                                placeholder: BLURRED
                                formats: [AUTO, WEBP, AVIF]
                            )
                        }
                        publicURL
                    }
                    formCtaImage {
                        childImageSharp {
                            gatsbyImageData(
                                layout: CONSTRAINED
                                placeholder: BLURRED
                                formats: [AUTO, WEBP, AVIF]
                            )
                        }
                        publicURL
                    }
                    formBackgroundImage {
                        childImageSharp {
                            gatsbyImageData(
                                layout: CONSTRAINED
                                placeholder: BLURRED
                                formats: [AUTO, WEBP, AVIF]
                            )
                        }
                        publicURL
                    }
                }
            }
        }
    }
`
