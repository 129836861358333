import * as React from "react"
import {ImageWrapper, TopText, Row, UspContainer, UspItem, StyledText} from "./styles"
import {UspBlockProps} from "./types"

const UspBlock: React.FC<UspBlockProps> = ({uspList}) => {
    return <UspContainer>
        <Row>
            {uspList && uspList.map((usp, index) => (
                <UspItem flipped={usp.flipped || false} key={index}>
                    {usp.imageOrText.image ? <ImageWrapper flipped={usp.flipped || false}>{usp.imageOrText.image}</ImageWrapper> :
                        <TopText color={usp.imageOrText.textColor || "#fff"}>{usp.imageOrText.text}</TopText>}
                    <StyledText>{usp.text}</StyledText>
                </UspItem>
            ))}
        </Row>
    </UspContainer>
}

export default UspBlock
