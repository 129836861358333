import * as React from "react"
import {useState} from "react"
import {
    CheckMarkWrapper,
    CountryWrapper,
    LinkWrapper,
    ModalLanguageSelector,
    OptionsWrapper,
    OptionWrapper,
    FooterLanguageSelectorWrapper,
    StyledSelect
} from "./styles"
import "swiper/css/bundle"
import {FooterLanguageSelectorProps} from "./types";
import ArrowDownSmall from "@icons/ArrowDownSmall";
import Button from "@components/Button";
import Modal from "@components/Modal";
import CheckMark from "@icons/CheckMark";
import {navigate} from "gatsby";
import {useIntl} from "react-intl";

const FooterLanguageSelector: React.FC<FooterLanguageSelectorProps> = ({
                                                                           languages, text
                                                                       }) => {
    const {locale} = useIntl()
    const [activeLanguage, setActiveLanguage] = useState(languages.find((language) => language.key === locale))
    const [showOptions, setShowOptions] = useState(false)
    return <div>{<FooterLanguageSelectorWrapper>
        <StyledSelect onClick={() => setShowOptions(true)}>
            <CountryWrapper showOptions={false}>
                {activeLanguage!.icon}
                <span>{activeLanguage!.title}</span>
            </CountryWrapper>
            <ArrowDownSmall/>
        </StyledSelect>
        <Modal small open={showOptions} onClose={() => setShowOptions(false)} /*mobileFullScreen={false}*/>
            <ModalLanguageSelector>
                <span>Select a language</span>
                <OptionsWrapper>
                    {showOptions && languages.map((language, index) => {
                        return <OptionWrapper className={activeLanguage === language ? 'active' : ""} onClick={() => {
                            setActiveLanguage(language)
                        }} key={index}>
                            <CountryWrapper showOptions={showOptions}>
                                {language.icon}
                                <span>{language.title}</span>
                            </CountryWrapper>
                            {language.key === locale &&
                            <CheckMarkWrapper>
                                <CheckMark/>
                            </CheckMarkWrapper>}
                        </OptionWrapper>
                    })}
                </OptionsWrapper>
                <LinkWrapper onClick={() => {
                    window.localStorage.setItem('orchid-spec-lang', activeLanguage!.key)
                    navigate(activeLanguage!.to)
                }}>
                    <Button
                        variant="primary">Continue</Button></LinkWrapper>
            </ModalLanguageSelector>
        </Modal>
    </FooterLanguageSelectorWrapper>}</div>
}

export default FooterLanguageSelector
