import styled from "@emotion/styled"
import Container from "@components/Container";

export const ContentContainer = styled(Container)(({theme}) => ({
    display: "flex",
    zIndex: 5,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    paddingTop: "0px !important",
    paddingBottom: "0px !important",
    [theme.breakpoint.down("lg")]: {
        marginTop: -100
    },
    [theme.breakpoint.down("md")]: {
        marginTop: 100
    },
}))

export const Wrapper = styled("div")(({theme}) => ({
    maxWidth: 750,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    color: "#fff",
    [theme.breakpoint.down("md")]: {
        textAlign: "left",
        alignItems: "flex-start",
    },
    "h1, h2": {
        marginBottom: 24,
    },
    "p": {
        fontSize: theme.fonts.pxToRem(18),
        margin: 0,
        fontFamily: theme.fonts.filson,
        marginBottom: 30,
        [theme.breakpoint.down("md")]: {
            fontSize: theme.fonts.pxToRem(16),
            marginBottom: 20,
        }
    },
    "& .name": {
        color: "#EEA995",
        fontSize: theme.fonts.pxToRem(26),
        fontFamily: theme.fonts.heading,
        margin: 0,
    },
    "& .jobDescription": {
        color: "#fff",
        opacity: .5,
        fontSize: theme.fonts.pxToRem(14),
        fontFamily: theme.fonts.filson,
        fontWeight: 300,
    },
    "& img": {
        userSelect: "none"
    }
}))