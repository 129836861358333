import * as React from "react"
import Hero from "@components/Hero"
import {GatsbyImage} from "gatsby-plugin-image"

const StrapiHero: React.FC = (props: any) => {
    const processedImage = props?.contentImages ? props.contentImages.map((image: any) => image?.heroImage?.childImageSharp?.gatsbyImageData).find((entry: any) => entry !== null) : null
    const processedBackgroundImage = props?.contentImages ? props.contentImages.map((image: any) => image?.heroBackgroundImage?.childImageSharp?.gatsbyImageData).find((entry: any) => entry !== null) : null
    const processedSvg = props?.contentImages ? props.contentImages.map((image: any) => image?.heroImage?.publicURL).find((entry: any) => entry !== undefined) : null
    const processedBackgroundSvg = props?.contentImages ? props.contentImages.map((image: any) => image?.heroBackgroundImage?.publicURL).find((entry: any) => entry !== undefined) : null

    return <Hero
        image={(processedImage !== null && processedImage !== undefined) ?
            <GatsbyImage image={processedImage} loading="eager" alt={props.image.alternativeText || ""}/> :
            <img src={processedSvg} alt={props.image.alternativeText || ""}/>}
        backgroundImage={(processedBackgroundImage !== null && processedBackgroundImage !== undefined) ?
            <GatsbyImage image={processedBackgroundImage} loading="eager"
                         alt={props.backgroundImage.alternativeText || ""}/> :
            <img src={processedBackgroundSvg} alt={props.backgroundImage.alternativeText || ""}/>}
        text={props?.text}
    />
}

export default StrapiHero