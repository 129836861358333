import styled from "@emotion/styled"
//@ts-ignore
export const UnstyledButton = styled.button(({theme}) => ({
    background: "none !important",
    border: "none !important",
    appearance: "none !important",
    outline: "none !important",
    width: "100% !important",
    height: "100% !important",
    "& a": {
        cursor: "pointer !important",
        position: "relative !important",
        width: "100% !important",
        display: "flex !important",
        alignItems: "center !important",
        justifyContent: "center !important",
        webkitAppearance: "none !important",
        border: "none !important",
        boxSizing: "border-box !important",
        "& *": {
            pointerEvents: "none !important",
        }
    },
    "&.primary_small a": {
        fontFamily: `${theme.fonts.filson} !important`,
        fontWeight: `${theme.fontWeights.bold} !important`,
        fontSize: `${theme.fonts.pxToRem(14)} !important`,
        lineHeight: "18px !important",
        color: "#fff !important",
        padding: "0 !important",
        position: "relative !important",
        top: "-10px !important",
        left: "-10px !important",
        margin: "10px !important",
        whiteSpace: "nowrap !important",
        background: "none !important",
        backgroundImage: `url("/images/small-button.svg") !important`,
        width: "100% !important",
        height: "100% !important",
    },
    "&.primary a": {
        background: "none !important",
        backgroundImage: `url("/images/button-background.svg") !important`,
        backgroundSize: "100% 100% !important",
        color: `${theme.colors.secondary} !important`,
        transition: "all 400ms ease !important",
    }
}))
