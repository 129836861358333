import styled from "@emotion/styled"
import Container from "@components/Container";

export const MenuContainer = styled("div", {
    shouldForwardProp: (prop) => prop !== "isOpen"
})<{ isOpen: boolean }>(({theme, isOpen}) => ({
    display: "flex",
    position: "fixed",
    top: 70,
    left: 0,
    paddingBottom: 20,
    zIndex: 1050,
    width: "100%",
    maxWidth: "100%",
    maxHeight: "100vh",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    pointerEvents: isOpen ? "all" : "none",
}))

export const MobileOverlay = styled("div", {
    shouldForwardProp: (prop) => prop !== "isOpen"
})<{ isOpen: boolean }>(({theme, isOpen}) => ({
    display: isOpen ? "block" : "none",
    opacity: .8,
    position: "absolute",
    paddingTop: 1,
    top: 70,
    left: 0,
    right: 0,
    zIndex: 1000,
    bottom: 0,
    backgroundColor: "#1A1A1A",
    height: "100vh",
    width: "100vw"
}))

export const MenuItem = styled("div")(({theme}) => ({
    borderBottom: `1px solid rgb(116, 149, 186, .2)`,
    cursor: "pointer",
    width: "100%",
    "& a": {
        fontFamily: theme.fonts.filson,
        fontWeight: theme.fontWeights.medium,
        fontSize: theme.fonts.pxToRem(20),
        lineHeight: "70px",
    }
}))

export const StyledContainer = styled(Container, {
    shouldForwardProp: (prop) => prop !== "isOpen"
})<{ isOpen: boolean }>(({theme, isOpen}) => ({
    position: "relative",
    transition: "all 1s ease",
    transform: isOpen? "translateY(0)" : "translateY(-100vh)",
    zIndex: 5,
    backgroundColor: "#fff",
    paddingTop: "0 !important",
    paddingBottom: "0 !important",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    maxWidth: "100% !important",
    [theme.breakpoint.down("lg")]: {
        paddingLeft: 40,
        paddingRight: 40
    }
}))

export const ButtonWrapper = styled("div")(({theme}) => ({
    margin: "40px 0",
    width: "100%",
    height: 50,
    [theme.breakpoint.down("md")]: {
        "& > *": {
            width: "100%",
            paddingLeft: 0,
            paddingRight: 0,
        }
    }
}))