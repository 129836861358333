import styled from "@emotion/styled"
import Container from "@components/Container";

export const StyledContainer = styled(Container)(({theme}) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    paddingTop: "40px !important",
    paddingBottom: "0 !important"
}))

export const VideoContainer = styled(Container)(({theme}) => ({
    paddingTop: "0 !important",
    [theme.breakpoint.down("lg")]: {
        paddingLeft: "0 !important",
        paddingRight: "0 !important",
        maxWidth: "100%",
        paddingBottom: "0 !important",
    },
}))


export const VideoOuterWrapper = styled.div(({theme}) => ({
    width: "100%",
    background : "#fff",
    overflow: "hidden",
    marginBottom: -100,
    [theme.breakpoint.down("md")]: {
      marginBottom: -60
    }
}))

export const IframeWrapper = styled.div(({theme}) => ({
    position: "relative",
    display: "block",
    overflow: "hidden",
    width: "100%",
    height: 0,
    paddingTop: "56.25%",
    [theme.breakpoint.down("md")]: {
        flexGrow: 1,
    },
    "& iframe": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
    },
}))

export const Wrapper = styled.div(({theme}) => ({
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: "80%",
    height: "auto",
    position: "relative",
    zIndex: 1,
    [theme.breakpoint.down("md")]: {
        maxWidth: "100%"
    },
    "& > img, & > .gatsby-image-wrapper": {
        display: "block",
        // maxWidth: "100%",
        width: "100%",
        height: "100%",
        objectFit: "cover",
        [theme.breakpoint.up("md")]: {
            borderRadius: 8,
        }
    },
    "&::after": {
        content: "''",
        position: "absolute",
        left: "-100vw",
        right: 0,
        bottom: "calc(-20%)",
        height: "70%",
        width: "300vw",
        background: theme.colors.primary,
        zIndex: -1,
        [theme.breakpoint.down("xl")]: {
            bottom: "calc(-23%)",
            height: "73%",
        },
        [theme.breakpoint.down("lg")]: {
            bottom: "calc(-22%)",
            height: "72%",
        },
        [theme.breakpoint.down("md")]: {
            display: "none"
        }
    }
}))

export const TextWrapper = styled.div(({theme}) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    maxWidth: 750,
    textAlign: "center",
    marginBottom: 60,
    "& h2": {
        marginBottom: 20
    },
    "& p": {
        color: theme.colors.secondary,
        lineHeight: "30px",
        fontFamily: theme.fonts.filson,
        fontSize: theme.fonts.pxToRem(18),
        marginBottom: 40
    },
    [theme.breakpoint.down("lg")]: {
        maxWidth: 480,
        "& p": {
            fontSize: theme.fonts.pxToRem(16),
            lineHeight: "26px",
            marginBottom: 30
        },
    },
    [theme.breakpoint.down("md")]: {
        maxWidth: 480,
        textAlign: "left",
        marginBottom: 68,
        "& p": {
            fontSize: theme.fonts.pxToRem(16),
            lineHeight: "26px",
            marginBottom: 0
        },
    },
}))

export const ActionWrapper = styled.div(({theme}) => ({
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    // backgroundColor: "rgba(35, 35, 35, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    cursor: "pointer",
    zIndex: 1,
}))

export const PlayButton = styled.div(({theme}) => ({
    width: 154,
    display: "flex",
    padding: "10px 10px 10px 20px",
    boxSizing: "border-box",
    alignItems: "center",
    justifyContent: "space-between",
    background: "#fff",
    borderRadius: 30,
    height: 48,
    fontFamily: theme.fonts.filson,
    fontWeight: theme.fontWeights.bold,
    fontSize: theme.fonts.pxToRem(15),
    lineHeight: "18px",
    "& img": {
        width: 30,
        height: 30
    }
}))