import styled from "@emotion/styled"

export const StyledContainer = styled("div")(({theme}) => ({
    padding: 40,
    minHeight: 400,
    display: "flex",
    flexDirection: "column",
    [theme.breakpoint.down("md")]: {
        padding: 30
    },
    [theme.breakpoint.down("sm")]: {
        padding: "30px 20px"
    },
    "& label": {
        fontFamily: theme.fonts.filson,
        fontWeight: theme.fontWeights.medium,
        fontSize: theme.fonts.pxToRem(10),
        lineHeight: "22px",
        opacity: .5,
        color: "#12162B",
        letterSpacing: 1,
        textTransform: "uppercase"
    },
    "& input, & select": {
        appearance: "none",
        padding: 16,
        fontFamily: theme.fonts.filson,
        fontSize: theme.fonts.pxToRem(14),
        lineHeight: "22px",
        border: "1px solid #EAEAEA",
        borderRadius: 4,
        width: "100%",
        boxSizing: "border-box",
        color: theme.colors.primary,
        "&::placeholder": {
            color: "#12162B",
            opacity: .5,
            fontWeight: theme.fontWeights.thin
        }
    },
    "& #form-holder": {
       flex: 1,
        display: "flex",
        alignItems: "center"
    },
    "& .hs-form-field": {
        width: "calc(50% - 16px)",
        display: "flex",
        flexDirection: "column",
        marginBottom: 18,
        "&.hs-fieldtype-select .input": {
            position: "relative",
            "&::after": {
                content: "''",
                backgroundImage: `url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 330 330' style='enable-background:new 0 0 330 330;' xml:space='preserve'%3E%3Cpath d='M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393 c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393 s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z'/%3E%3C/svg%3E%0A")`,
                backgroundPosition: "center",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                right: 15,
                pointerEvents: "none",
                top: "50%",
                marginTop: -6,
                width: 12,
                height: 12,
                position: "absolute",
            }
        },
        [theme.breakpoint.down("lg")]: {
            width: "100%",
            marginBottom: 14
        }
    },
    "& .hs-error-msgs": {
        listStyle: "none",
        padding: 0,
        marginTop: 6,
        marginBottom: 0
    },
    "& .hs-error-msg": {
        color: "#DC5353",
        opacity: 1
    },
    "& .submitted-message": {
        width: "fit-content",
        marginLeft: "auto",
        marginRight: "auto",
        padding: 40,
        fontFamily: theme.fonts.filson,
        fontWeight: theme.fontWeights.medium,
        fontSize: theme.fonts.pxToRem(26),
        lineHeight: "26px",
        height: "50%",
        display: "flex",
        alignItems: "center",
        [theme.breakpoint.down("md")]: {
           position: "relative",
            padding: "70px 0px 40px 0px",
        },
        "&::before": {
            content: "''",
            display: "block",
            backgroundImage: `url(/images/circle-check.svg)`,
            backgroundSize: "contain",
            minWidth: 60,
            minHeight: 60,
            [theme.breakpoint.down("md")]: {
                position: "absolute",
                top: 0,
                left: "calc(50% - 30px)"
            }
        }
    },
    "& form": {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-between"
    },
    "& .hs_error_rollup": {
        display: "none"
    },
    "& .hs_submit": {
        marginTop: 10,
        marginLeft: "auto",
        width: "100%",
        [theme.breakpoint.down("md")]: {
            marginLeft: 0,
            marginRight: "auto"
        },
        "& .hs-button": {
            flex: 1,
            cursor: "pointer",
            fontFamily: theme.fonts.filson,
            fontWeight: theme.fontWeights.bold,
            fontSize: theme.fonts.pxToRem(16),
            lineHeight: "19px",
            color: "#fff",
            width: "100%",
            height: 54,
            padding: 0,
            display: "flex",
            alignItems: 'center',
            justifyContent: "center",
            backgroundImage: `url("/images/button-large.svg")`,
            backgroundColor: "#fff",
            border: "none",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            [theme.breakpoint.down("md")]: {
                marginTop: 10
            }
        }
    }
}))
