import * as React from "react"
import {ImageContainer, Next, Prev, QuoteContainer, StyledSwiper, TextContainer} from "./styles"
import {QuoteProps} from "./types"
import {Lazy, Navigation, Pagination, Autoplay} from "swiper";
import useSwiperRef from "@hooks/useSwiperRef";
import {SwiperSlide} from "swiper/react"
import ArrowSecondaryLeft from "@icons/ArrowSecondaryLeft";
import ArrowSecondaryRight from "@icons/ArrowSecondaryRight";

const Quote: React.FC<QuoteProps> = ({quoteList}) => {
    const [nextEl, nextElRef] = useSwiperRef<HTMLDivElement>()
    const [prevEl, prevElRef] = useSwiperRef<HTMLDivElement>()

    return <QuoteContainer>
        <Prev ref={prevElRef}>
            <ArrowSecondaryLeft/>
        </Prev>
        <Next ref={nextElRef}>
            <ArrowSecondaryRight/>
        </Next>
        <StyledSwiper
            lazy
            modules={[Pagination, Navigation, Autoplay, Lazy]}
            navigation={{
                nextEl: nextEl,
                prevEl: prevEl,
            }}
            autoplay={
                {
                    delay: 10000,
                    pauseOnMouseEnter: true
                }
            }
            pagination={{clickable: true}}
            slidesPerView={1}
            spaceBetween={20}
        >
            {quoteList && quoteList.map((quote, index) => (
                <SwiperSlide key={index}>
                    <TextContainer dangerouslySetInnerHTML={{__html: quote.content}}/>
                    <ImageContainer>
                        {quote.image}
                    </ImageContainer>
                </SwiperSlide>
            ))}
        </StyledSwiper>
    </QuoteContainer>
}

export default Quote
