import styled from "@emotion/styled"
import Container from "@components/Container"

export const TextWithListWrapper = styled.div({
    backgroundColor: "#9DC5BC",
})

export const StyledContainer = styled(Container)(({theme}) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    [theme.breakpoint.down("md")]: {
        paddingBottom: 110,
        paddingTop: 100
    }
}))

export const Row = styled("div")(({theme}) => ({
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    position: "relative",
    width: "100%"
}))

export const UspItem = styled("div")(({theme}) => ({
    color: "#fff",
    width: "33.3333%",
    flex: "1 0 33.3333%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 48,
    [theme.breakpoint.down("lg")]: {
        width: "50%",
        flex: "1 1 50%"
    },
    [theme.breakpoint.down("md")]: {
        width: "100%",
        flex: "1 1 100%",
        marginBottom: 30,
    }
}))

export const TextWrapper = styled("div")(({theme}) => ({
    maxWidth: 750,
    color: theme.colors.primary,
    textAlign: "center",
    marginBottom: 100,
    "& h2": {
        marginBottom: 24,
        [theme.breakpoint.down("lg")]: {
            marginBottom: 18,
        }
    },
    "& p": {
        fontFamily: theme.fonts.filson,
        fontSize: theme.fonts.pxToRem(18),
        color: theme.colors.secondary,
        lineHeight: 1.65,
        [theme.breakpoint.down("md")]: {
            lineHeight: 1.6
        }
    },
    [theme.breakpoint.down("lg")]: {
        marginBottom: 80,
        "& p": {
            fontSize: theme.fonts.pxToRem(18)
        },
    },
    [theme.breakpoint.down("md")]: {
        marginBottom: 50,
        "& p": {
            fontSize: theme.fonts.pxToRem(16)
        },
        textAlign: "left"
    }
}))

export const StyledText = styled("div")(({theme}) => ({
    fontFamily: theme.fonts.filson,
    fontSize: theme.fonts.pxToRem(16),
    maxWidth: 350,
    lineHeight: 1.65,
    textAlign: "center",
    color: theme.colors.primary,
    [theme.breakpoint.down("md")]: {
        lineHeight: 1.4,
        fontSize: theme.fonts.pxToRem(14),
    }
}))

export const UspTextWrapper = styled("div")(({theme}) => ({
    display: "flex",
    maxWidth: 350,
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoint.down("md")]: {
        marginBottom: 10,
    },
    "& p": {
        color: theme.colors.primary,
    },
    "& span": {
        marginRight: 10,
        fontSize: theme.fonts.pxToRem(70),
        lineHeight: "97px",
        color: theme.colors.primary,
        [theme.breakpoint.down("md")]: {
            fontSize: theme.fonts.pxToRem(48),
            lineHeight: "50px",
        },
        "&:last-child": {
            marginLeft: 10,
            fontSize: theme.fonts.pxToRem(16),
            lineHeight: "24px",
        }
    }
}))