import * as React from "react"
import {useEffect, useState} from "react"
import {
    ArrowWrapper,
    CloseButton,
    CountryWrapper,
    LanguageSelectorWrapper,
    LinkWrapper,
    OptionsContainer,
    OptionsWrapper,
    OptionWrapper,
    RightSide,
    StyledContainer,
    StyledSelect,
    TextWrapper,
    StyledNav
} from "./styles"
import "swiper/css/bundle"
import {LanguageSelectorProps} from "@components/LanguageSelector/types";
import ArrowDownSmall from "@icons/ArrowDownSmall";
import CloseIcon from "@icons/CloseIcon";
import Button from "@components/Button";
import {navigate} from "gatsby";
import {useIntl} from "react-intl";
import isBrowser from "@utils/isBrowser";
import {enabledLocales} from "../../i18n";
import {Locales} from "../../i18n/types";
import {getI18nMessages} from "../../i18n/messages";

const LanguageSelector: React.FC<LanguageSelectorProps> = ({
                                                               text,
                                                               languages,
                                                               show,
                                                               onClose
                                                           }) => {
    const {messages, locale} = useIntl()
    const [activeLanguage, setActiveLanguage] = useState(languages.find((language) => language.key === locale))
    const [showOptions, setShowOptions] = useState(false)
    const chosenLanguage = isBrowser && window.localStorage.getItem('orchid-spec-lang')

    useEffect(() => {
        const browserLanguage = enabledLocales.includes(navigator.language as Locales) ? languages.find((language) => language.key === navigator.language) : null
        if(browserLanguage) setActiveLanguage(browserLanguage)
    }, [])
    if(chosenLanguage) return <div />
    return <StyledNav>{<LanguageSelectorWrapper show={show}>
        <StyledContainer>
            <TextWrapper>
                <p>{text}</p>
            </TextWrapper>
            <RightSide>
                {!showOptions ? <StyledSelect onClick={() => setShowOptions(true)}>
                        <CountryWrapper showOptions={showOptions}>
                            {activeLanguage!.icon}
                            <span>{activeLanguage!.title}</span>
                        </CountryWrapper>
                        <ArrowDownSmall/>
                    </StyledSelect> :
                    <OptionsContainer>
                        <OptionsWrapper>
                            {showOptions && languages.map((language, index) => {
                                return <OptionWrapper onClick={() => {
                                    setActiveLanguage(language)
                                    setShowOptions(false)
                                }} key={index}>
                                    <CountryWrapper showOptions={showOptions}>
                                        {language.icon}
                                        <span>{language.title}</span>
                                    </CountryWrapper>
                                    {index === 0 &&
                                    <ArrowWrapper>
                                        <ArrowDownSmall stroke="black"/>
                                    </ArrowWrapper>}
                                </OptionWrapper>
                            })}
                        </OptionsWrapper>
                    </OptionsContainer>}
                <LinkWrapper onClick={() => {
                    window.localStorage.setItem('orchid-spec-lang', activeLanguage!.key)
                    onClose()
                    navigate(activeLanguage!.to)
                }}><Button
                    variant="primary">{enabledLocales.includes(navigator.language as Locales) ? getI18nMessages(navigator.language as Locales).continue : getI18nMessages('en-GB').continue}</Button></LinkWrapper>
                <CloseButton onClick={() => onClose()}><CloseIcon/></CloseButton>
            </RightSide>
        </StyledContainer>
    </LanguageSelectorWrapper>}
    </StyledNav>
}

export default LanguageSelector
