import * as React from "react"
import {css, Global, useTheme} from "@emotion/react"
import styled from "@emotion/styled"
import emotionNormalize from "emotion-normalize"
import "@fonts/typekit.css"

export const GlobalStyles = ({invert}: { invert?: boolean }) => {
    const theme = useTheme()

    return (
        <>
            <Global
                styles={css`
                    ${emotionNormalize}
                `}
            />
            <Global
                styles={{
                    html: {
                        boxSizing: "border-box",
                        textSizeAdjust: "100%",
                    },
                    body: {
                        "&::-webkit-scrollbar": {
                            display: "none"
                        },
                        fontFamily: theme.fonts.body,
                        fontWeight: theme.fontWeights.body,
                        fontSize: "1rem",
                        color: theme.colors.primary,
                        lineHeight: 1,
                        transition:
                            "background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,fill 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                        "& a": {
                            transition:
                                "background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,fill 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                        },
                    },
                    ".gatsby-focus-wrapper": {
                        overflow: "hidden",
                    },
                    h1: {
                        fontFamily: theme.fonts.heading,
                        margin: 0,
                        fontSize: theme.fonts.pxToRem(64),
                        lineHeight: 1.3,
                        [theme.breakpoint.down("md")]: {
                            fontSize: theme.fonts.pxToRem(46),
                        },
                        [theme.breakpoint.down("sm")]: {
                            fontSize: theme.fonts.pxToRem(32),
                        },
                        [theme.breakpoint.down("xs")]: {
                            fontSize: theme.fonts.pxToRem(25),
                        }
                    },
                    h2: {
                        fontFamily: theme.fonts.heading,
                        margin: 0,
                        fontSize: theme.fonts.pxToRem(64),
                        lineHeight: 1.3,
                        [theme.breakpoint.down("md")]: {
                            fontSize: theme.fonts.pxToRem(56),
                        },
                        [theme.breakpoint.down("xs")]: {
                            fontSize: theme.fonts.pxToRem(40),
                        }
                    },
                    h3: {
                        fontFamily: theme.fonts.heading,
                        fontWeight: "normal",
                        marginTop: 0,
                        marginBottom: 20,
                        "&:last-child": {
                            marginBottom: 0,
                        },
                        [theme.breakpoint.up("lg")]: {
                            marginBottom: 30,
                        },
                        fontSize: theme.fonts.pxToRem(32),
                        lineHeight: 1.3,
                        [theme.breakpoint.down("sm")]: {
                            fontSize: theme.fonts.pxToRem(24),
                        }
                    },
                    h4: {
                        fontFamily: theme.fonts.body,
                        fontSize: theme.fonts.pxToRem(32),
                        fontWeight: theme.fontWeights.body,
                        marginTop: 0,
                        lineHeight: 1.2,
                        marginBottom: "1rem",
                        "&:last-child": {
                            marginBottom: 0,
                        },
                        [theme.breakpoint.up("lg")]: {
                            fontSize: theme.fonts.pxToRem(36),
                        },
                    },
                    p: {
                        fontWeight: theme.fontWeights.thin,
                        marginTop: 0,
                        marginBottom: "1.5rem",
                        lineHeight: 1.6,
                        "&:last-child": {
                            marginBottom: 0,
                        },
                        [theme.breakpoint.up("lg")]: {
                            marginBottom: "1.75rem",
                        },
                        "& a": {
                            textDecoration: "none",
                            borderColor: "inherit",
                        },
                    },
                    a: {
                        textDecoration: "none",
                        color: "inherit",
                    },
                    "b, strong": {
                        fontWeight: theme.fontWeights.bold,
                    },
                    ".overline": {
                        letterSpacing: 2,
                        fontSize: "1rem",
                        fontFamily: theme.fonts.body,
                        fontWeight: theme.fontWeights.body,
                        marginBottom: "1rem",
                    },
                    ".medium": {
                        fontSize: "1rem",
                        fontFamily: theme.fonts.body,
                        fontWeight: theme.fontWeights.medium,
                        marginBottom: "1rem",
                        color: theme.colors.text
                    },
                    ".lightParagraph": {
                        fontSize: "1rem",
                        fontFamily: theme.fonts.body,
                        marginBottom: "1rem",
                        color: theme.colors.text
                    },
                    ".headingLine": {
                        fontFamily: theme.fonts.heading,
                        fontSize: theme.fonts.pxToRem(30),
                        [theme.breakpoint.up("md")]: {
                            fontSize: theme.fonts.pxToRem(40),
                        },
                        "&::after": {
                            content: "''",
                            display: "block",
                            marginTop: 20,
                            backgroundColor: theme.colors.tertiary,
                            height: 4,
                            width: 60
                        },
                        "&[style*=\"text-align: center;\"], &[style*=\"text-align:center;\"]": {
                            "&::after": {
                                marginLeft: "auto",
                                marginRight: "auto",
                            }
                        }
                    },
                    ".btn": {
                        height: 48,
                        width: 180,
                        borderRadius: 5,
                        color: "#fff",
                        fontSize: theme.fonts.pxToRem(14),
                        fontWeight: theme.fontWeights.semibold,
                        letterSpacing: 0.6,
                        textTransform: "uppercase",
                        textDecoration: "none",
                        display: "flex",
                        textAlign: "center",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "10px 23px",
                        boxSizing: "border-box",
                        border: "1px solid transparent",
                        marginBottom: 20,
                        transition: "border 0.3s ease, background 0.3s ease, color 0.3s ease",
                        [theme.breakpoint.up("md")]: {
                            display: "inline-flex",
                            marginRight: 20
                        },
                    },
                    ".btn-primary": {
                        background: theme.colors.primary,
                        borderColor: theme.colors.primary,
                        "&:hover": {
                            background: theme.colors.tertiary,
                            borderColor: theme.colors.tertiary,
                        }
                    },
                    ".btn-secondary": {
                        background: theme.colors.secondary,
                        borderColor: theme.colors.secondary,
                        "&:hover": {
                            background: theme.colors.primary,
                            borderColor: theme.colors.primary,
                        }
                    },
                    ".btn-tertiary": {
                        background: theme.colors.tertiary,
                        borderColor: theme.colors.tertiary,
                        "&:hover": {
                            background: theme.colors.primary,
                            borderColor: theme.colors.primary,
                        }
                    },
                    ".desktop": {
                        [theme.breakpoint.down("lg")]: {
                            display: "none",
                        }
                    },
                    ".mobile": {
                        [theme.breakpoint.up("lg")]: {
                            display: "none",
                        }
                    }
                }}
            />
        </>
    )
}

export const Main = styled.main(({theme}) => ({
    maxWidth: 1730,
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: theme.colors.primary,
    overflow: "hidden"
}))
