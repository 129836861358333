
import {useEffect, useState} from 'react'
import isBrowser from "@utils/isBrowser";

/**
 * Accepts a media query string then uses the
 * [window.matchMedia](https://developer.mozilla.org/en-US/docs/Web/API/Window/matchMedia) API to determine if it
 * matches with the current document.<br />
 * It also monitor the document changes to detect when it matches or stops matching the media query.<br />
 * Returns the validity state of the given media query.
 *
 */
const useMediaQuery = (mediaQuery: string) => {
    const isApiSupported = (api: string) => (typeof window !== 'undefined' ? api in window : false)

    if (!isBrowser || !isApiSupported('matchMedia')) {
        return null
    }

    const [isVerified, setIsVerified] = useState(!!window.matchMedia(mediaQuery).matches)

    useEffect(() => {
        const mediaQueryList = window.matchMedia(mediaQuery)
        const documentChangeHandler = () => setIsVerified(!!mediaQueryList.matches)

        try {
            mediaQueryList.addEventListener("change", documentChangeHandler)
        } catch (e1) {
            try {
                mediaQueryList.addListener(documentChangeHandler)
            } catch (e2) {
                console.error(e2);
            }
        }

        documentChangeHandler()
        return () => {
            try {
                mediaQueryList.removeEventListener("change", documentChangeHandler)
            } catch (e1) {
                try {
                    mediaQueryList.removeListener(documentChangeHandler)
                } catch (e2) {
                    console.error(e2);
                }
            }
        }
    }, [mediaQuery])

    return isVerified
}

export default useMediaQuery