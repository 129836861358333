import * as React from "react"
import {GatsbyImage} from "gatsby-plugin-image"
import Quote from "@components/Quote"

const StrapiQuote: React.FC = (props: any) => {

    const processedImages = props?.contentImages?.map((image: any, index: number) => {
        if (image?.quoteImage?.childImageSharp?.gatsbyImageData) {
            return <GatsbyImage image={image.quoteImage.childImageSharp.gatsbyImageData}
                                alt={props.quoteList[index].image.alternativeText || ""}/>
        } else if (image?.quoteImage?.publicURL) {
            return <img src={image.quoteImage.publicURL} alt={props.quoteList[index].alternativeText || ""}/>
        } else {
            return {}
        }
    })

    const quoteList = props?.quoteList?.map((quote: any, index: number) => {
        if (quote?.image) {
            quote.image = processedImages[index]
        }
        return quote
    })

    return <Quote
        quoteList={quoteList}
    />
}

export default StrapiQuote