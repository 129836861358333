import * as React from "react"
import {useCallback, useEffect, useState} from "react"
import {useIntl} from 'react-intl';
import {
    ActiveBullet,
    ButtonWrapper,
    CloseButtonWrapper,
    HamburgerMenu,
    ImageWrapper,
    LinkWrapper,
    MenuWrapper,
    SpanMenu,
    StyledContainer
} from "./styles"
import {MenuProps} from "./types"
import "swiper/css/bundle"
import useSticky from "@hooks/useSticky"
import {Link} from "gatsby"
import Button from "@components/Button"
import CloseIcon from "@icons/CloseIcon"
import MobileMenu from "@components/Menu/MobileMenu"
import isBrowser from "@utils/isBrowser"
import {moveToAnchor} from "@utils/moveToAnchor"
import {MobileOverlay} from "@components/Menu/MobileMenu/styles";
import LanguageSelector from "@components/LanguageSelector";
import {enabledLocales} from "../../i18n";
import {Locales} from "../../i18n/types";
import {getI18nMessages} from "../../i18n/messages";

const listenerOptions: AddEventListenerOptions & EventListenerOptions = {passive: true}

const Menu: React.FC<MenuProps> = ({logo, languages, linkItems, button}) => {
    const isSticky = useSticky()
    const {messages} = useIntl()
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const anchorArray = linkItems ? linkItems.filter(item => item.linkType === "anchor").map(item => isBrowser && document.getElementById(item.link)) as HTMLDivElement[] : []
    const menuOffset = 80
    const {label: buttonLabel, ...buttonProps} = button

    const onScroll = useCallback(() => {
        const scrollPos = window.scrollY

        anchorArray.forEach((child, index) => {
            if (child) {
                const linkItem = document.querySelector(`a[id="anchor-${child.getAttribute('id')}"]`) as HTMLAnchorElement
                const container = child.nextSibling as HTMLDivElement
                if (container) {
                    if ((scrollPos + menuOffset) > container.offsetTop
                        && (scrollPos + menuOffset) < container.offsetTop + container.offsetHeight
                    ) {
                        linkItem.classList.add('active')
                    } else {
                        linkItem.classList.remove('active')
                    }
                }
            }
        })
    }, [anchorArray])
    const [showLangSelector, setShowLangselector] = useState(isBrowser && !window.localStorage.getItem('orchid-spec-lang'))
    const languageSelectText = isBrowser && enabledLocales.includes(navigator.language as Locales) ? getI18nMessages(navigator.language as Locales).langSwitchText : getI18nMessages('en-GB').langSwitchText
    const {locale} = useIntl()
    useEffect(() => {
        if (anchorArray && menuOffset) {
            window.addEventListener('scroll', onScroll, listenerOptions)
        }

        return () => {
            if (anchorArray && menuOffset) {
                window.removeEventListener('scroll', onScroll, listenerOptions)
            }
        }
    }, [anchorArray, menuOffset])
    return <>
        {languages.length > 1 && (isBrowser && !navigator.language.includes(locale)) && <LanguageSelector
            text={languageSelectText}
            languages={languages}
            onClose={() => setShowLangselector(false)}
            show={showLangSelector}
        />}
        <MenuWrapper extraOffset={languages.length > 1 && showLangSelector && (isBrowser && !navigator.language.includes(locale))} isSticky={isSticky}>
            <StyledContainer>
                <ImageWrapper>
                    {logo}
                </ImageWrapper>
                <LinkWrapper>
                    {linkItems && linkItems.map((link, index) => {
                        const linkProps = link.linkType === "anchor" ? {
                            id: `anchor-${link.link}`,
                            onClick: (e: any) => {
                                e.preventDefault()
                                moveToAnchor(link.link)
                            },
                            to: link.link
                        } : {
                            to: link.link,
                            target: "_blank"
                        }

                        return <Link key={index} {...linkProps}>
                            {link.label}
                            <ActiveBullet/>
                        </Link>
                    })}
                </LinkWrapper>
                <ButtonWrapper>
                    <Button {...buttonProps}>{buttonLabel}</Button>
                </ButtonWrapper>
                <HamburgerMenu
                    isOpen={isMenuOpen}
                    onClick={() => setIsMenuOpen(true)}
                >
                    <SpanMenu>
                        <span/>
                        <span/>
                        <span/>
                    </SpanMenu>
                    {messages.menu}
                </HamburgerMenu>
                <CloseButtonWrapper isOpen={isMenuOpen}>
                    <Button iconLeft={<CloseIcon/>} onClick={() => setIsMenuOpen(false)}
                            variant="close_button">{messages.close}</Button>
                </CloseButtonWrapper>
            </StyledContainer>
            <MobileMenu
                isMenuOpen={isMenuOpen}
                setIsMenuOpen={setIsMenuOpen}
                links={linkItems}
                button={button}
            />
            <MobileOverlay isOpen={isMenuOpen}/>
        </MenuWrapper>
    </>
}

export default Menu
