import * as React from "react"
import Comparison from "@components/Comparison";
import {GatsbyImage} from "gatsby-plugin-image";

const StrapiComparison: React.FC = (props: any) => {

    const processedImage = props?.contentImages ? props.contentImages.map((image: any) => image?.comparisonImage?.childImageSharp?.gatsbyImageData).find((entry: any) => entry !== null) : null
    const processedSvg = props?.contentImages ? props.contentImages.map((image: any) => image?.comparisonImage?.publicURL).find((entry: any) => entry !== undefined) : null

    const tableHeader = {
        featureHeader: props.tableHeader.featureHeader,
        column1: (processedImage !== null && processedImage !== undefined) ?
            <GatsbyImage image={processedImage} alt={props.tableHeader.column1.alternativeText || ""}/> :
            <img src={processedSvg} alt={props.tableHeader.column1.alternativeText || ""}/>,
        column2: props.tableHeader.column2,
        column3: props.tableHeader.column3
    }
    return <Comparison
        content={props.content}
        tableHeader={tableHeader}
        rows={props.rows}
        bottomCta={props.bottomCta}
    />
}

export default StrapiComparison