import * as React from "react"
import {GlobalStyles, Main} from "./styles"
import {ThemeProvider} from "@emotion/react"
import {theme} from "theme"
import Footer from "@components/Footer"
import Menu from "@components/Menu";
import {graphql, PageProps, useStaticQuery} from "gatsby"
import {GatsbyImage} from "gatsby-plugin-image";
import {moveToAnchor} from "@utils/moveToAnchor"
import {IntlProvider} from "react-intl"
import {getI18nMessages, translationIcons, translationLabels} from "../../i18n/messages"
import {MainLayoutProps} from "./types"
import {Helmet} from "react-helmet";
const MainLayout: React.FC<MainLayoutProps> = ({children, location, locale, translations}) => {
    const defaultMessages = getI18nMessages("en-GB")
    const messages = {
        ...defaultMessages,
        ...getI18nMessages(locale)
    }

    const data = useStaticQuery<GatsbyTypes.strapiSettingsQueryQuery>(graphql`
        query strapiSettingsQuery {
            site {
                siteMetadata {
                    siteUrl
                }
            }
            allStrapiSettings {
                nodes {
                    locale
                    contentImages {
                        images {
                            menuLogo {
                                childImageSharp {
                                    gatsbyImageData(
                                        layout: CONSTRAINED
                                        placeholder: BLURRED
                                        formats: [AUTO, WEBP, AVIF]
                                    )
                                }
                                publicURL
                            }
                            footerLogo {
                                childImageSharp {
                                    gatsbyImageData(
                                        layout: CONSTRAINED
                                        placeholder: BLURRED
                                        formats: [AUTO, WEBP, AVIF]
                                    )
                                }
                                publicURL
                            }
                        }
                    }
                    menu {
                        button {
                            label
                            link
                            linkType
                            variant
                            portalId
                            ctaId
                        }
                        linkItems {
                            label
                            link
                            linkType
                        }
                        logo {
                            alternativeText
                        }
                    }
                    footer {
                        bottomLinks {
                            link
                            label
                            linkType
                        }
                        contactInfo
                        logo {
                            alternativeText
                        }
                        quickLinksFooter {
                            leftColumn {
                                label
                                link
                                linkType
                            }
                            rightColumn {
                                label
                                link
                                linkType
                            }
                        }
                    }
                    languageSelectTextFooter
                }
            }
        }
    `)

    const strapiSettings = data?.allStrapiSettings?.nodes?.find(item => item.locale === locale)
    const contentImages = strapiSettings.contentImages[0].images
    const processedMenuLogo = contentImages ? contentImages.map((image: any) => image?.menuLogo?.childImageSharp?.gatsbyImageData).find((entry: any) => entry !== null) : null
    const processedMenuLogoSvg = contentImages ? contentImages.map((image: any) => image?.menuLogo?.publicURL).find((entry: any) => entry !== undefined) : null
    const processedFooterLogo = contentImages ? contentImages.map((image: any) => image?.footerLogo?.childImageSharp?.gatsbyImageData).find((entry: any) => entry !== null) : null
    const processedFooterLogoSvg = contentImages ? contentImages.map((image: any) => image?.footerLogo?.publicURL).find((entry: any) => entry !== undefined) : null

    // Custom changes for non-homepages
    const noMainMenu = location?.pathname?.includes("foko")
    const customButtonProps = noMainMenu ? {
        ctaId: null,
        portalId: null,
        link: null,
        linkType: null,
        url: "/free-trial",
        isInternal: true,
        label: "Bestellen Sie Ihr Musterpaket",
        onClick: (e: any) => {
            e.preventDefault()
            moveToAnchor("free-trial")
        },
    } : {}

    const currentTranslation = {
        key: locale,
        title: translationLabels[locale],
        icon: translationIcons[locale],
        to: location?.pathname || ""
    }

    const formattedTranslations = translations ? translations.map(item => {
        const formattedItem = {
            key: item.locale,
            title: translationLabels[item.locale],
            icon: translationIcons[item.locale],
            to: item.slug
        }
        return formattedItem
    }) : []

    const allTranslations = formattedTranslations.concat(currentTranslation)

    const siteUrl = data?.site?.siteMetadata?.siteUrl || ""

    return <IntlProvider locale={locale} messages={messages}>
        <Helmet>
            {allTranslations.map((locale, index) => (
                <link key={index} rel="alternate" href={`${siteUrl}${locale.to}`} hrefLang={locale.key}/>
            ))}
        </Helmet>
        <ThemeProvider theme={theme}>
            <GlobalStyles/>
            <Menu
                logo={(processedMenuLogo !== null && processedMenuLogo !== undefined) ?
                    <GatsbyImage image={processedMenuLogo} loading="eager"
                                 alt={strapiSettings.menu.logo.alternativeText || ""}/> :
                    <img src={processedMenuLogoSvg} alt={strapiSettings.menu.logo.alternativeText || ""}/>}
                button={{
                    ...strapiSettings.menu.button,
                    ...customButtonProps
                }}
                languages={allTranslations}
                linkItems={noMainMenu ? [] : strapiSettings.menu.linkItems}
            />
            <Main>{children}</Main>
            <Footer
                languageSelectText={strapiSettings.languageSelectTextFooter}
                languages={allTranslations}
                logo={(processedFooterLogo !== null && processedFooterLogo !== undefined) ?
                    <GatsbyImage image={processedFooterLogo} loading="eager"
                                 alt={strapiSettings.footer.logo.alternativeText || ""}/> :
                    <img src={processedFooterLogoSvg} alt={strapiSettings.footer.logo.alternativeText || ""}/>}
                quickLinksFooter={strapiSettings.footer.quickLinksFooter}
                contactInfo={strapiSettings.footer.contactInfo}
                bottomLinks={strapiSettings.footer.bottomLinks}
            />
        </ThemeProvider>
    </IntlProvider>
}

export default MainLayout
