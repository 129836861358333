import styled from "@emotion/styled"
import Container from "@components/Container";

export const HeroWrapper = styled.div(({theme}) => ({
    width: "content-fit",
    display: "flex",
    flexDirection: "column",
    zIndex: 1,
    marginTop: 100,
    [theme.breakpoint.down("lg")]: {
        marginTop: 150
    },
    [theme.breakpoint.down("md")]: {
        marginTop: 0
    }
}))

export const OuterHeroContainer = styled(Container)(({theme}) => ({
   padding: "0 !important",
    position: "relative",
    [theme.breakpoint.down("xl")]: {
        maxWidth: "100%"
    }
}))

export const HeroContainer = styled(Container)(({theme}) => ({
    minHeight: 1024,
    height: "80vh",
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    position: "relative",
    paddingBottom: "0px !important",
    // [theme.breakpoint.down("xl")]: {
    //     maxWidth: "100%",
    // },
    [theme.breakpoint.down("md")]: {
        alignItems: "center",
        height: "auto",
        minHeight: "100vw",
        justifyContent: "flex-start"
    }
}))

export const ImageWrapper = styled("div")(({theme}) => ({
    alignSelf: "left",
    marginLeft: -100,
    marginTop: 20,
    [theme.breakpoint.down("lg")]: {
        marginLeft: -57,
        "& img": {
            width: 180
        }
    },
    [theme.breakpoint.down("md")]: {
        marginLeft: 0,
        "& img": {
            width: "30vw"
        }
    },
    [theme.breakpoint.down("sm")]: {
        "& img": {
            width: "40vw"
        }
    }
}))

export const BackgroundWrapper = styled("div")(({theme}) => ({
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    pointerEvents: "none",
    userSelect: "none",
    "& img": {
        display: "block",
        width: "100%",
        height: "100%",
        objectFit: "cover",
        [theme.breakpoint.down("md")]: {
            height: "auto",
            width: "150vw",
            position: 'absolute',
            left: "50%",
            transform: "translateX(-50%)"
        },
        [theme.breakpoint.up("xxl")]: {
            width: 1730,
            position: 'absolute',
            top: -360,
            left: "50%",
            transform: "translateX(-50%)",
            height: "auto",
        }
    },
    [theme.breakpoint.down("md")]: {
        top: -50,
        left: -100,
        bottom: "auto"
    }
}))

export const Text = styled("div")(({theme}) => ({
    "& h1": {
        color: theme.colors.primary,
    }
}))