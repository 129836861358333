import * as React from "react"
import {GatsbyImage} from "gatsby-plugin-image"
import ComingSoon from "@components/ComingSoon";

const StrapiComingSoon: React.FC = (props: any) => {
    const processedImage = props?.contentImages ? props.contentImages.map((image: any) => image?.comingSoonImage?.childImageSharp?.gatsbyImageData).find((entry: any) => entry !== null) : null
    const processedBackgroundImage = props?.contentImages ? props.contentImages.map((image: any) => image?.comingSoonBackgroundImage?.childImageSharp?.gatsbyImageData).find((entry: any) => entry !== null) : null
    const processedSvg = props?.contentImages ? props.contentImages.map((image: any) => image?.comingSoonImage?.publicURL).find((entry: any) => entry !== undefined) : null
    const processedBackgroundSvg = props?.contentImages ? props.contentImages.map((image: any) => image?.comingSoonBackgroundImage?.publicURL).find((entry: any) => entry !== undefined) : null

    return <ComingSoon
        image={processedImage
            ? <GatsbyImage image={processedImage} alt={props.image.alternativeText || ""}/>
            : processedSvg
                ? <img src={processedSvg} alt={props.image.alternativeText || ""}/>
                : undefined}
        backgroundImage={processedBackgroundImage
            ? <GatsbyImage image={processedBackgroundImage} alt={props.backgroundImage.alternativeText || ""}/>
            : processedBackgroundSvg
                ? <img src={processedBackgroundSvg} alt={props.backgroundImage.alternativeText || ""}/>
                : undefined}
        content={props.content}
        list={props.list}
    />
}

export default StrapiComingSoon
