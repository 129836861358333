import React, {useState} from 'react'
import {
    ComparisonWrapper,
    CtaWrapper,
    FooterWrapper,
    ImageColumn,
    SpanWrapper,
    StyledContainer,
    Table,
    TableBody,
    TableColumn,
    TableFooter,
    TableHeader,
    TableRow,
    TableWideColumn,
    TextWrapper,
    ButtonWrapper
} from "./styles"
import {ComparisonProps} from "./types"
import useMediaQuery from "@hooks/useMediaQuery"
import Button from "@components/Button"
import icons from "@components/Strapi/icons";
import {useIntl} from "react-intl";

const Comparison: React.FC<ComparisonProps> = ({content, bottomCta, tableHeader, rows}) => {
    const [allFeaturesOpen, setAllFeaturesOpen] = useState(false)
const {messages} = useIntl()
    return <ComparisonWrapper>
        <StyledContainer>
            <TextWrapper>
                <div dangerouslySetInnerHTML={{__html: content}}/>
            </TextWrapper>
            <Table>
                <TableHeader>
                    <TableWideColumn>
                        {tableHeader.featureHeader}
                    </TableWideColumn>
                    <TableColumn>
                        <p className={"mobile"}>Orchid spec</p>
                        <p className={"desktop"}>{tableHeader.column1}</p>
                    </TableColumn>
                    <TableColumn>
                        <p>{tableHeader.column2}</p>
                    </TableColumn>
                    <TableColumn>
                        <p>{tableHeader.column3}</p>
                    </TableColumn>
                </TableHeader>
                <TableBody className={"tableBody"}>
                    {rows && rows.map((row, index) => (
                        <TableRow className={`tableRow ${index > 2 && !allFeaturesOpen ? "hidden" : ""}`}
                                  isShown={allFeaturesOpen} key={index}>
                            <TableWideColumn>
                                {row.featureTitle}
                            </TableWideColumn>
                            <ImageColumn>
                                {row?.column1?.icon && icons[row.column1.icon]}
                                {row?.column1?.text && row.column1.text}
                            </ImageColumn>
                            <ImageColumn>
                                {row?.column2?.icon && icons[row.column2.icon]}
                                {row?.column2?.text && row.column2.text}
                            </ImageColumn>
                            <ImageColumn>
                                {row?.column3?.icon && icons[row.column3.icon]}
                                {row?.column3?.text && row.column3.text}
                            </ImageColumn>
                        </TableRow>
                    ))}
                </TableBody>
                <TableFooter onClick={(e) => {
                    setAllFeaturesOpen(!allFeaturesOpen)
                }}>
                    <FooterWrapper>
                        <SpanWrapper isOpen={allFeaturesOpen}
                        >
                            <span/>
                            <span/>
                        </SpanWrapper>
                        <p>{allFeaturesOpen ? messages.collapseFeatures : messages.seeFeatures}</p>
                    </FooterWrapper>
                </TableFooter>
            </Table>
            <CtaWrapper>
                <p>{bottomCta.title}</p>
                <ButtonWrapper>
                    <Button url={bottomCta.button.link} variant={bottomCta.button.variant}>{bottomCta.button.label}</Button>
                </ButtonWrapper>
            </CtaWrapper>
        </StyledContainer>
    </ComparisonWrapper>
}

export default Comparison
