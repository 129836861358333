import styled from "@emotion/styled"
import Container from "@components/Container"
import {Swiper} from "swiper/react"

export const QuoteContainer = styled(Container)(({theme}) => ({
    position: "relative",
    maxWidth: "1080px !important",
    paddingBottom: "0px !important",
    paddingTop: "66px !important",
    [theme.breakpoint.up("md")]: {
        paddingLeft: 80,
        paddingRight: 80,
    },
    [theme.breakpoint.up("xl")]: {
        paddingLeft: 40,
        paddingRight: 40,
    }
}))

export const TextContainer = styled("div")(({theme}) => ({
    color: "#fff",
    width: "66.6666%",
    flex: "1 1 66.6666%",
    paddingRight: 70,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    fontSize: theme.fonts.pxToRem(32),
    fontStyle: "italic",
    fontFamily: theme.fonts.filson,
    [theme.breakpoint.down("lg")]: {
        fontSize: theme.fonts.pxToRem(24)
    },
    [theme.breakpoint.down("md")]: {
        fontSize: theme.fonts.pxToRem(22),
        alignItems: "center",
        textAlign: "center",
        paddingRight: 0,
        marginBottom: 0,
        width: "100%",
        flex: "1 1 100%",
    },
    "p": {
        lineHeight: 1.3,
    },
    "& .quoteName": {
        display: "block",
        fontSize: theme.fonts.pxToRem(18),
        lineHeight: "30px",
        fontFamily: theme.fonts.filson,
        opacity: .5,
        marginTop: 30,
        [theme.breakpoint.down("md")]: {
            marginTop: 0
        },
    }
}))

export const ImageContainer = styled("div")(({theme}) => ({
    width: "33.3333%",
    minWidth: 380,
    flex: "1 0 33.333%",
    transform: "translateY(50px)",
    position: "relative",
    zIndex: 10,
    "& img": {
        width: "100%",
        height: "100%"
    },
    [theme.breakpoint.down("lg")]: {
        minWidth: 280
    },
    [theme.breakpoint.down("md")]: {
        transform: "translateY(30px)",
        minWidth: 180
    }
}))

export const Next = styled("div")(({theme}) => ({
    position: "absolute",
    bottom: 200,
    right: -20,
    cursor: "pointer",
    width: 46,
    height: 46,
    [theme.breakpoint.down("lg")]: {
        bottom: 170,
    },
    [theme.breakpoint.down("md")]: {
        bottom: 150,
        right: 15
    },
    [theme.breakpoint.up("md")]: {
        right: 20
    },
    [theme.breakpoint.up("xl")]: {
        right: -20
    },
    zIndex: 2,
    "&.swiper-button-disabled": {
        display: "none"
    }
}))

export const Prev = styled(Next)(({theme}) => ({
    left: -20,
    right: 0,
    [theme.breakpoint.down("md")]: {
        left: 15
    },
    [theme.breakpoint.up("md")]: {
        left: 20
    },
    [theme.breakpoint.up("xl")]: {
        left: -20
    },
}))

export const StyledSwiper = styled(Swiper)(({theme}) => ({
    height: "100%",
    overflow: "visible",
    "&.swiper": {
        overflow: "visible",
    },
    "& .swiper-wrapper": {
        alignItems: "center",
        webkitTransformStyle: "preserve-3d"
    },
    "& .swiper-slide": {
        display: "flex",
        [theme.breakpoint.down("md")]: {
            flexDirection: "column",
            alignItems: "center"
        },
        opacity: 0,
        webkitTransform: "translateZ(0)",
        webkitBackfaceVisibility: "hidden",
        transition: "all .7s",
        "&.swiper-slide-active": {
            opacity: 1
        },
        "& > img, > .gatsby-image-wrapper": {
            width: "80%",
            marginLeft: "auto",
            marginRight: "auto",
            height: "100%",
            objectFit: "contain",
            display: "block",
            background: "transparent",
            position: "relative",
            zIndex: 5
        },
    },
    "& .swiper-pagination": {
        [theme.breakpoint.down("md")]: {
            bottom: "-50px !important"
        },
    },
    "& .swiper-pagination-bullet": {
        width: 12,
        height: 12,
        background: "#fff",
        opacity: 1,
        marginLeft: "0 !important",
        marginRight: "12px !Important",
        "&:last-child": {
            marginRight: "0 !important"
        }
    },
    "& .swiper-pagination-bullet-active": {
        background: "#50BA8E"
    }
}))