import LoadingIcon from "@icons/LoadingIcon"
import KruisjeIcon from "@icons/Kruisje";
import VinkjeIcon from "@icons/Vinkje";
import KruisjeVinkjeIcon from "@icons/Kruisje_vinkje";

const icons: any = {
    loadingIcon: LoadingIcon(),
    kruisje: KruisjeIcon(),
    vinkje: VinkjeIcon(),
    kruisje_vinkje: KruisjeVinkjeIcon()
}

export default icons
