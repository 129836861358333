import * as React from "react"

const ArrowLeftIcon = () => <svg width="46" height="46" viewBox="0 0 46 46" xmlns="http://www.w3.org/2000/svg"
                                 xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
        <filter x="-13.8%" y="-8.8%" width="127.5%" height="127.5%" filterUnits="objectBoundingBox"
                id="arrow-left_svg__a">
            <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1"/>
            <feGaussianBlur stdDeviation="1.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
            <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" in="shadowBlurOuter1"/>
        </filter>
        <filter colorInterpolationFilters="auto" id="arrow-left_svg__c">
            <feColorMatrix in="SourceGraphic"
                           values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"/>
        </filter>
        <ellipse id="arrow-left_svg__b" cx="19.986" cy="19.988" rx="19.986" ry="19.988"/>
        <path
            d="M7.78872566,0.778310074 C7.35887687,1.20248246 7.35427524,1.8948034 7.77844762,2.32465219 C7.78185082,2.32810093 7.78527691,2.33152702 7.78872566,2.33493022 L12.1784307,6.66666667 L12.1784307,6.66666667 L7.78872566,10.9984031 C7.35887687,11.4225755 7.35427524,12.1148964 7.77844762,12.5447452 C7.78185082,12.548194 7.78527691,12.5516201 7.78872566,12.5550233 C8.22601254,12.9865355 8.92889009,12.9865355 9.36617697,12.5550233 L15.3333333,6.66666667 L15.3333333,6.66666667 L9.36617697,0.778310074 C8.92889009,0.346797822 8.22601254,0.346797822 7.78872566,0.778310074 Z"
            id="arrow-left_svg__d"/>
    </defs>
    <g transform="matrix(-1 0 0 1 42.972 1)" fill="none" fillRule="evenodd">
        <use fill="#000" filter="url(#arrow-left_svg__a)" xlinkHref="#arrow-left_svg__b"/>
        <use fill="#000F63" xlinkHref="#arrow-left_svg__b"/>
        <g filter="url(#arrow-left_svg__c)">
            <g transform="translate(12 13)">
                <line x1="12.5" y1="6.5" x2=".5" y2="6.5" stroke="#000" strokeWidth="2.2" strokeLinecap="round"/>
                <use fill="#000" xlinkHref="#arrow-left_svg__d"/>
            </g>
        </g>
    </g>
</svg>

export default ArrowLeftIcon
