import * as React from "react"
import {
    BackgroundWrapper,
    Column,
    CtaImageMobileWrapper,
    CtaImageWrapper,
    FormOuterWrapper,
    FormWrapper,
    Number,
    Row,
    StyledContainer,
    StyledFormContainer,
    TextBlock,
    Wrapper
} from "./styles"
import {FormProps} from "./types"
import HubspotForm from "@components/HubspotForm"

const Form: React.FC<FormProps> = ({
                                       content,
                                       backgroundImage,
                                       ctaImage,
                                       formTitle ,
                                       textBlocks,
                                       portalId,
                                       formId
                                   }) => {
    return <FormOuterWrapper id="free-trial">
        <StyledContainer>
            <Wrapper>
                <div dangerouslySetInnerHTML={{__html: content}}/>
            </Wrapper>
            {ctaImage && <CtaImageWrapper>{ctaImage}</CtaImageWrapper>}
        </StyledContainer>
        <StyledFormContainer noCtaImage={!ctaImage}>
            <FormWrapper>
                <Column>
                    <HubspotForm
                        title={formTitle}
                        portalId={portalId}
                        formId={formId}
                    />
                </Column>
                <Column>
                    {textBlocks && textBlocks.map((textBlock, index) => (
                        <TextBlock key={index}>
                            <Row><Number>{textBlock.number}</Number><h3>{textBlock.title}</h3></Row>
                            <div dangerouslySetInnerHTML={{__html: textBlock.content}}/>
                        </TextBlock>
                    ))}
                </Column>
            </FormWrapper>
            <CtaImageMobileWrapper>{ctaImage}</CtaImageMobileWrapper>
        </StyledFormContainer>
        {backgroundImage && <BackgroundWrapper>
            {backgroundImage}
        </BackgroundWrapper>}
    </FormOuterWrapper>
}

export default Form
