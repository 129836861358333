import * as React from "react"
import VideoComponent from "@components/VideoComponent";
import {GatsbyImage} from "gatsby-plugin-image";

const StrapiVideoComponent: React.FC = (props: any) => {

    const processedImage = props?.contentImages ? props?.contentImages.map((image: any) => image?.videoPosterImage?.childImageSharp?.gatsbyImageData).find((entry: any) => entry !== undefined) : null
    const processedSvg = props?.contentImages ? props.contentImages.map((image: any) => image?.videoPosterImage?.publicURL).find((entry: any) => entry !== undefined) : null

    const video = {
        youtubeId: props.video.youtubeId,
        buttonLabel: props.video.buttonLabel,
        posterImage: (processedImage !== null && processedImage !== undefined) ?
            <GatsbyImage image={processedImage} alt={props.video.posterImage.alternativeText || ""}/> :
            <img src={processedSvg} alt={props.video.posterImage.alternativeText || ""}/>
    }

    return <VideoComponent content={props.content} video={video}/>

}

export default StrapiVideoComponent