import * as React from "react"
import TextWithList from "@components/TextWithList";

const StrapiTextWithList: React.FC = (props: any) => {

    return <TextWithList
        content={props.content}
        list={props.list}
    />
}

export default StrapiTextWithList