import styled from "@emotion/styled"
import Container from "@components/Container";
import {Link} from "gatsby";

export const StyledContainer = styled(Container)(({theme}) => ({
    position: "relative",
    fontFamily: theme.fonts.filson,
    paddingTop: "16px !important",
    paddingBottom: "16px !important",
    alignItems: "center",
    height: 70,
    display: "flex",
    justifyContent: "space-between",
    maxWidth: "100% !important",
    [theme.breakpoint.down("md")]: {
        height: 120,
        flexWrap: "wrap",
        justifyContent: "center"
    }
}))

export const TextWrapper = styled.div(({theme}) => ({
    color: "white",
    fontSize: theme.fonts.pxToRem(12),
    lineHeight: 16 / 12,
    maxWidth: 500
}))

export const CountryWrapper = styled("div", {
    shouldForwardProp: prop => prop !== "showOptions",
})<{ showOptions: boolean }>(({theme, showOptions}) => ({
    color: showOptions ? "black" : "white",
    display: "flex",
    alignItems: "center",
    fontFamily: theme.fonts.filson,
    fontSize: theme.fonts.pxToRem(12),
    lineHeight: 14 / 12,
    fontWeight: theme.fontWeights.thin,
    marginRight: 20,
    whiteSpace: "nowrap",
    "& svg": {
        width: 15,
        height: 12,
        flexShrink: 0,
        marginRight: 10
    }
}))


export const LanguageSelectorWrapper = styled("div", {
    shouldForwardProp: prop => prop !== "show",
})<{ show: boolean }>(({theme, show}) => ({
    width: "100%",
    height: 70,
    backgroundColor: "#0F196E",
    display: show ? "block" : "none",
    [theme.breakpoint.down("md")]: {
        height: 120
    }
}))

export const StyledNav = styled.nav(({theme}) => ({
    zIndex: 1200,
    position: "fixed",
    top: 0,
    width: "100%"
}))

export const StyledSelect = styled.div(({theme}) => ({
    display: "flex",
    marginRight: 14,
    color: "white",
    alignItems: "center",
    justifyContent: "space-between",
    height: 38,
    boxSizing: "border-box",
    padding: 12,
    border: "1px solid rgb(255, 255, 255, .2)",
    borderRadius: 4,
    fontSize: theme.fonts.pxToRem(12),
    lineHeight: 14 / 12,
    cursor: "pointer",
    width: 180
}))

export const CloseButton = styled.div(({theme}) => ({
    cursor: "pointer",
    marginLeft: 20
}))

export const RightSide = styled.div(({theme}) => ({
    display: "flex",
    alignItems: "center"
}))

export const OptionsContainer = styled.div(({theme}) => ({
    marginRight: 14,
    height: 38,
    width: 180
}))

export const OptionsWrapper = styled.div(({theme}) => ({
    borderRadius: 4,
    overflow: "hidden"
}))


export const OptionWrapper = styled.div(({theme}) => ({
    background: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 12,
    cursor: "pointer",
    "&:not(:last-child)": {
        borderBottom: "1px solid #E6E6E6"
    },
    "&:hover": {
        background: "#E6E6E6"
    }
}))

export const LinkWrapper = styled.div(({theme}) => ({
    "& button": {
        height: 38
    }
}))

export const ModalLanguageSelector = styled.div(({theme}) => ({
    padding: "50px 42px",
    "& > span": {
        fontFamily: theme.fonts.filson,
        fontSize: theme.fonts.pxToRem(22),
        lineHeight: 28 / 22,
        color: theme.colors.primary
    },
    [`${OptionsWrapper}`]: {
        width: "100%",
        overflow: "visible",
        borderRadius: "none",
        marginTop: 18
    },
    [`${OptionWrapper}`]: {
        boxSizing: "border-box",
        border: "1px solid #E6E6E6",
        borderRadius: 4,
        height: 38,
        width: "100%",
        marginBottom: 10,
        "&:last-child": {
            marginBottom: 22
        },
        "&:hover": {
            borderColor: "black",
            background: "white"
        },
        "&.active": {
            border: "1px solid black",
        }
    }
}))

export const CheckMarkWrapper = styled.div(({theme}) => ({
    "& svg": {
        width: 18,
        height: 18,
        transform: "none"
    }
}))

export const ArrowWrapper = styled.div(({theme}) => ({
    display: "flex",
    alignItems: "center",
    "& svg": {
        width: 10,
        height: 6,
        flexShrink: 0,
        transform: "rotate(180deg)"
    }
}))
