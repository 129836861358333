import * as React from "react"
import {
    Highlight,
    HighlightRow,
    Next,
    Prev,
    ProductHighlightsWrapper,
    StyledContainer,
    StyledMobileSwiper,
    StyledSwiper,
    TextWrapper
} from "./styles"
import {ProductHighlightsProps} from "./types"
import {Navigation, Lazy, Pagination} from "swiper"
import ArrowLeftIcon from "@icons/ArrowLeft"
import ArrowRightIcon from "@icons/ArrowRight"
import useSwiperRef from "@hooks/useSwiperRef"
import "swiper/css/bundle"
import {SwiperSlide} from "swiper/react"

const ProductHighlights: React.FC<ProductHighlightsProps> = ({content, slides, mobileSlides}) => {
    const [nextEl, nextElRef] = useSwiperRef()
    const [prevEl, prevElRef] = useSwiperRef()

    return <ProductHighlightsWrapper>
        <StyledContainer>
            <TextWrapper>
                <div dangerouslySetInnerHTML={{__html: content}}/>
            </TextWrapper>
            <Prev ref={prevElRef}>
                <ArrowLeftIcon/>
            </Prev>
            <Next ref={nextElRef}>
                <ArrowRightIcon/>
            </Next>
            <StyledSwiper
                lazy
                modules={[Pagination, Navigation, Lazy]}
                navigation={{
                    nextEl: nextEl,
                    prevEl: prevEl,
                }}
                pagination={{clickable: true}}
                slidesPerView={1}
                spaceBetween={20}
                // cssMode
            >
                {slides && slides.map((slide, index) => (
                    <SwiperSlide key={index}>
                        {slide.image}
                        <HighlightRow>
                            {slide.highlights && slide.highlights.map((highlight, index) => (
                                <Highlight key={index}>
                                    <h3>{highlight.title}</h3>
                                    <p>{highlight.text}</p>
                                </Highlight>
                            ))}
                        </HighlightRow>
                    </SwiperSlide>
                ))}
            </StyledSwiper>
            <StyledMobileSwiper
                lazy
                modules={[Pagination, Navigation, Lazy]}
                navigation={{
                    nextEl: nextEl,
                    prevEl: prevEl,
                }}
                centeredSlides
                // cssMode
                pagination={{clickable: true}}
                slidesPerView={1}
                spaceBetween={20}
            >
                {mobileSlides && mobileSlides.map((slide, index) => (
                    <SwiperSlide key={index}>
                        {slide.image}
                        <HighlightRow>
                            <Highlight key={index}>
                                <h3>{slide.highlight.title}</h3>
                                <p>{slide.highlight.text}</p>
                            </Highlight>
                        </HighlightRow>
                    </SwiperSlide>
                ))}
            </StyledMobileSwiper>
        </StyledContainer>
    </ProductHighlightsWrapper>
}

export default ProductHighlights
