import * as React from "react"
import {
    BottomLinksContainer,
    ContactInfoWrapper,
    FooterContainer,
    FooterLangueSelectorWrapper,
    FooterWrapper,
    InnerFooterWrapper,
    InnerWrapper,
    Line,
    LogoWrapper,
    QuickLinkColumn,
    QuickLinkContainer,
    QuickLinksWrapper
} from "./styles"
import {FooterProps} from "./types"
import {Link} from "gatsby";
import {moveToAnchor} from "@utils/moveToAnchor";
import FooterLanguageSelector from "@components/LanguageSelector/FooterLanguageSelector";

const Footer: React.FC<FooterProps> = ({languageSelectText, logo, languages, quickLinksFooter, bottomLinks, contactInfo}) => {

    const columns = [quickLinksFooter.leftColumn, quickLinksFooter.rightColumn]

    return <FooterWrapper id="footer">
        <FooterContainer>
            <LogoWrapper>{logo}</LogoWrapper>
            <InnerFooterWrapper>
                <QuickLinksWrapper>
                    <span>Quick links</span>
                    <QuickLinkContainer>
                        {columns.map((column, index) => {
                            return <QuickLinkColumn key={index}>
                                {column.map((link, index) => {
                                    const linkProps = link.linkType === "anchor" ? {
                                        id: `anchor-${link.link}`,
                                        onClick: (e: any) => {
                                            e.preventDefault()
                                            moveToAnchor(link.link)
                                        },
                                        to: link.link
                                    } : {
                                        to: link.link,
                                        target: "_blank"
                                    }
                                    return <Link key={index} {...linkProps}>{link.label}</Link>
                                })}
                            </QuickLinkColumn>
                        })}
                    </QuickLinkContainer>
                </QuickLinksWrapper>
                <ContactInfoWrapper>
                    <span>Contact info</span>
                    <div dangerouslySetInnerHTML={{__html: contactInfo}}/>
                </ContactInfoWrapper>
            </InnerFooterWrapper>
        </FooterContainer>
        <Line/>
        <BottomLinksContainer>
            {bottomLinks && bottomLinks.map((link, index) => (
                <Link key={index} to={link.link}>{link.label}</Link>
            ))}
            {languages.length > 1 && <FooterLangueSelectorWrapper>
                <InnerWrapper>
                    <span>{languageSelectText}</span>
                    <FooterLanguageSelector
                        languages={languages}
                    />
                </InnerWrapper>
            </FooterLangueSelectorWrapper>}
        </BottomLinksContainer>
    </FooterWrapper>
}

export default Footer
