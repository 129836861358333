import styled from "@emotion/styled"
import {ButtonProps} from "@components/Button/types"

export const PlainButton = styled.button<Partial<ButtonProps>>(({theme, size}) => ({
    cursor: "pointer",
    position: "relative",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    webkitAppearance: "none",
    // height: 54,
    // "&:not([disabled]):hover": {
    //     background: theme.colors.secondary,
    //     transition: "all 400ms ease",
    // },
    // "&[disabled]": {
    //     background: `${theme.colors.primary} !important`,
    //     cursor: "not-allowed"
    // },
    border: "none",
    boxSizing: "border-box",
    "& *": {
        pointerEvents: "none",
    },
    "& svg": {
        transition: "all 400ms ease",
    },
    "& span": {
        fontFamily: theme.fonts.filson,
        fontWeight: theme.fontWeights.bold,
        fontSize: theme.fonts.pxToRem(16),
        lineHeight: "19px",
        color: "#fff",
        padding: "0 !important",
        whiteSpace: "nowrap",
    },
    "&.primary": {
        background: "none",
        backgroundImage: `url("/images/button-background.svg")`,
        backgroundSize: "100% 100%",
        color: theme.colors.secondary,
        transition: "all 400ms ease",
    },
    "&.primary_small": {
        background: "none",
        backgroundImage: `url("/images/small-button.svg")`,
        color: theme.colors.secondary,
        transition: "all 400ms ease",
        padding: "0 10px !important",
        height: 38,
        "& span": {
            fontFamily: theme.fonts.filson,
            fontWeight: theme.fontWeights.bold,
            fontSize: theme.fonts.pxToRem(14),
            lineHeight: "18px",
            color: "#fff",
            padding: "0 !important",
            whiteSpace: "nowrap",
        },
    },
    "&.close_button": {
        background: "none",
        backgroundImage: `url("/images/close-button.svg")`,
        color: theme.colors.secondary,
        padding: "0 !important",
        width: 83,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: 37,
        "& span": {
            fontFamily: theme.fonts.filson,
            fontSize: theme.fonts.pxToRem(12),
            lineHeight: "17px",
            color: "#fff",
            padding: "0 !important",
            whiteSpace: "nowrap",
        },
    },
    padding: "12px 30px",
    [theme.breakpoint.down("md")]: {
        padding: "12px 20px",
    }
}))

export const ImageContainer = styled.div(({theme}) => ({
    display: "flex",
    alignItems: "center",
    "& svg": {
        // marginLeft: 20,
        marginRight: -6,
        marginTop: -9,
        marginBottom: -9,
    },
    "&.icon-right": {
        "& svg": {
            marginLeft: 20,
        },
    },
    "&.icon-left": {
        "& svg": {
            marginRight: 6,
        },
    },
    "&.icon-loading": {
        "& svg": {
            marginLeft: 10,
            width: 24,
            height: 24,
        },
    },
}))

export const IconButton = styled.button(({theme}) => ({
    cursor: "pointer",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0 2px 4px 0 rgba(0,0,0,0.20)",
    background: "#f5f5f5",
    padding: 14,
    border: "none",
    transition: "all 400ms ease",
    width: 40,
    height: 40,
    "& *": {
        pointerEvents: "none",
    },
    "& svg": {
        transition: "all 400ms ease",
        width: 12,
        height: 12,
    },
    "&.primary": {
        background: theme.colors.primary,
        color: theme.colors.secondary,
        transition: "all 400ms ease",
        "&:hover": {
            background: theme.colors.tertiary,
            color: theme.colors.secondary,
        },
    },
}))
