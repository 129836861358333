import * as React from "react"
import {ReactNode} from "react"
import enUS from "./en-US"
import enGB from "./en-GB"
import deDe from "./de-DE"
import deCH from "./de-CH"
import frCH from "./fr-CH"
import frFR from "./fr-FR"
//@ts-ignore
import {CH, DE, GB, US, FR} from "country-flag-icons/react/3x2"
import {defaultLocale} from "../../constants"
import {i18nMessagesProps, Locales} from "../types"

export const getI18nMessages = (langKey: Locales): i18nMessagesProps => {
    if (langKey === "en-US") {
        return enUS
    } else if (langKey === "de-DE") {
        return deDe
    } else if (langKey === "de-CH") {
        return deCH
    } else if (langKey === "fr-CH") {
        return frCH
    } else if (langKey === "fr-FR") {
        return frFR
    }

    return enGB
}

export const translationLabels: { [key in Locales]: string } = {
    "en-GB": "United Kingdom",
    "en-US": "United States",
    "de-DE": "Deutschland",
    "de-CH": "Schweiz",
    "fr-CH": "Suisse",
    "fr-FR": "France"
}

export const translationIcons: { [key in Locales]: ReactNode } = {
    "en-GB": <GB/>,
    "en-US": <US/>,
    "de-DE": <DE/>,
    "de-CH": <CH/>,
    "fr-CH": <CH/>,
    "fr-FR": <FR/>
}

export const getLocaleByUri = (uri?: string) => {
    if (uri) {
        const match = uri.match('^\/([a-z]{2})\/')
        if (match?.[1] && Object.keys(translationLabels).includes(match[1])) {
            return match[1]
        }
    }

    return defaultLocale
}
