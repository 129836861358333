import * as React from "react"
import {GatsbyImage} from "gatsby-plugin-image"
import UspIconBlock from "@components/UspIconBlock"

const StrapiUspIconBlock: React.FC = (props: any) => {
    const processedImages = props?.contentImages.map((image: any, index) => {
        if (image?.uspIconBlockImage?.childImageSharp?.gatsbyImageData) {
            return <GatsbyImage image={image.uspIconBlockImage.childImageSharp.gatsbyImageData}
                                alt={props.uspIconList[index].image.alternativeText || ""}/>
        } else if (image?.uspIconBlockImage?.publicURL) {
            return <img src={image.uspIconBlockImage.publicURL} alt={props.uspIconList[index].alternativeText || ""}/>
        }
    })
    
    const uspIconList = props.uspIconList.map((usp: any, index: number) => {
        if (usp?.image) {
            usp.image = processedImages[index]
        }
        return usp
    })

    return <UspIconBlock content={props.content} uspIconList={uspIconList} bottomCta={props.bottomCta}/>
}

export default StrapiUspIconBlock